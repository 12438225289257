import { TestLocators } from 'constants/Locators';

import cn from 'clsx';
import { Loader } from 'impact-react-components';

import { useStyles } from './styles';

export interface OverlayProps {
  size?: number;
  className?: string;
  testId?: TestLocators | string;
}

export function Overlay({ size, className, testId }: OverlayProps) {
  const classes = useStyles();

  return (
    <div className={cn(classes.overlayWrapper, className)} data-testid={testId ? `${testId}_Overlay` : undefined}>
      <Loader size={size} />
    </div>
  );
}
