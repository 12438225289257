import { PayloadAction } from '@reduxjs/toolkit';

import { CurveType } from 'types/Curve';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ImpactCurvesSettingsState } from './types';

export const initialState: ImpactCurvesSettingsState = {
  type: CurveType.LIQUID,
  duration: 12,
  parameterValue: true,
};

const mapWidgetsSlice = createSlice({
  name: 'impactCurvesSettings',
  initialState,
  reducers: {
    setCurvesSettings(state, { payload }: PayloadAction<ImpactCurvesSettingsState>) {
      return {
        ...state,
        ...payload,
      };
    },
    changeType(state, { payload: type }: PayloadAction<CurveType>) {
      state.type = type;
    },
    changeDuration(state, { payload: duration }: PayloadAction<number>) {
      state.duration = duration;
    },
    changeParameterValue(state, { payload: showParameterValue }: PayloadAction<boolean>) {
      state.parameterValue = showParameterValue;
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsSlice;
