import { ThemeMode } from 'impact-react-components';

import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const overrideStyles = withStyles((theme: Theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;
  const paperBorderStyle = `1px solid ${isDarkTheme ? colors.grey40 : colors.grey15}`;

  return createStyles({
    root: {
      width: '100%',
    },
    input: {
      borderRadius: 2,
      position: 'relative',
      backgroundColor: isDarkTheme ? colors.baseGrey80 : colors.primaryWhite,
      border: isDarkTheme ? 'none' : `1px solid ${colors.grey15}`,
      fontSize: 16,
      width: '100%',
      height: 32,
      padding: '0 30px 0 8px',
    },
    paper: {
      backgroundColor: isDarkTheme ? colors.grey56 : colors.primaryWhite,
      boxShadow: isDarkTheme ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '0 3px 4px 1px rgb(0, 0, 0, 0.25)',
      border: paperBorderStyle,
      borderRadius: 2,
    },
    listbox: {
      padding: 0,
      maxHeight: 'inherit',
    },
    option: {
      border: '1px solid transparent',
      padding: '4px 8px',
    },
    loading: {
      padding: 8,
    },
    clearIndicator: {
      backgroundColor: 'transparent !important',
    },
    popupIndicator: {
      backgroundColor: 'transparent !important',
    },
  });
});
