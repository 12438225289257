import { WellParametersWells } from 'app/components/containers/layout/ConfigTabs/PlannerFilter/WellParametersSection/types';
import { MapSettingsSavingData, WellSavingData } from 'app/components/containers/layout/ConfigTabs/Scenario/types';
import { MapLayerSettings } from 'app/components/containers/map/MapLayersSelect/types';
import { WellSettings } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/types';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';

export function getWellSavingData(
  wellSettings: WellSettings[],
  filterWellsSettings: WellParametersWells,
  activeWellId: number | null,
  geologic_province: string,
): WellSavingData[] {
  return wellSettings.reduce<WellSavingData[]>((acc, { id, name, color, startPoint, endPoint }) => {
    if (endPoint !== null) {
      acc.push({
        id,
        name,
        color,
        startPoint,
        endPoint,
        active: activeWellId !== null && id === activeWellId,
        geologic_province,
        ...filterWellsSettings[id],
      });
    }

    return acc;
  }, []);
}

export function getMapSettingsSavingData(
  mapLayersSettings: MapLayerSettings[],
  tooltipItems: ImpactWellAndProdColumn[],
): MapSettingsSavingData {
  return {
    layerItems: mapLayersSettings.map((layer) => ({
      id: layer.id,
      opacity: layer.opacity,
      size: layer.size,
      colorByField: layer.colorByValue?.field ?? null,
    })),
    tooltipItems: tooltipItems,
  };
}
