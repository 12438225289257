import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import {
  MapLngLat,
  MapLngLatBounds,
  MapStyle,
  MapWidgetState,
  MapWidgetsState,
} from 'app/components/containers/widgets/MapWidget/types';
import { FieldInfo } from 'types/FieldInfo';
import { MapWidgetId } from 'types/Widget';
import { createSlice } from 'utils/@reduxjs/toolkit';

const initialMapWidgetState: MapWidgetState = {
  style: MapStyle.CURRENT,
  center: null,
  zoom: null,
  selectedUwi: null,
  bounds: null,
  flyToCenter: null,
  boundsStatus: RequestStatus.IDLE,
  boundsError: null,
  renderLayersEnabled: true,
  isFirstTimeSelectedWellSetting: true,
};

export const initialState: MapWidgetsState = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: initialMapWidgetState,
  [MapWidgetId.ECONOMICS_MAP_WIDGET]: initialMapWidgetState,
  [MapWidgetId.PLAN_MAP_WIDGET]: { ...initialMapWidgetState, renderLayersEnabled: false },
};

const mapWidgetsSlice = createSlice({
  name: 'mapWidgets',
  initialState,
  reducers: {
    setRenderLayersEnabled(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; enabled: boolean }>) {
      state[payload.mapWidgetId].renderLayersEnabled = payload.enabled;
    },
    setMapStyle(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; mapStyle: MapStyle }>) {
      state[payload.mapWidgetId].style = payload.mapStyle;
    },
    setMapPosition(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; zoom: number; center: MapLngLat }>) {
      state[payload.mapWidgetId].center = payload.center;
      state[payload.mapWidgetId].zoom = payload.zoom;
    },
    setMapFlyToCenter(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; center: MapLngLat | null }>) {
      state[payload.mapWidgetId].flyToCenter = payload.center;
    },
    setSelectedUwi(
      state,
      { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; uwi: string; isFirstTimeSelected?: boolean }>,
    ) {
      state[payload.mapWidgetId].selectedUwi = payload.uwi;

      if (payload.isFirstTimeSelected !== undefined && payload.isFirstTimeSelected) {
        state[payload.mapWidgetId].isFirstTimeSelectedWellSetting = false;
      }
    },
    resetSelectedUwi(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId }>) {
      state[payload.mapWidgetId].selectedUwi = null;
    },
    loadBounds(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; filter?: Omit<FieldInfo, 'type'>[] }>) {
      state[payload.mapWidgetId].boundsStatus = RequestStatus.LOADING;
      state[payload.mapWidgetId].boundsError = null;
    },
    boundsLoaded(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; bounds: MapLngLatBounds | null }>) {
      state[payload.mapWidgetId].boundsStatus = RequestStatus.SUCCESS;
      state[payload.mapWidgetId].bounds = payload.bounds;
    },
    boundsError(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; error: string }>) {
      state[payload.mapWidgetId].boundsStatus = RequestStatus.FAILURE;
      state[payload.mapWidgetId].boundsError = payload.error;
      state[payload.mapWidgetId].bounds = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsSlice;
