import { EDatabaseName } from 'helpers/constants';
import { EDomain, domainName } from 'helpers/domain';

export const isUserFromIframBasedOnWindowObject = window.self !== window.top;

export const setSelectedDatabaseByOrigin = (selectedDB: EDatabaseName, availableDB: EDatabaseName[]) => {
  const userDomainName = domainName();

  if (selectedDB === EDatabaseName.OLD_HEAVYAI) {
    if (
      availableDB.length > 1 &&
      ((isUserFromIframBasedOnWindowObject && availableDB.includes(EDatabaseName.CSM)) ||
        (userDomainName === EDomain.FULL_SP_DOMAIN && availableDB.includes(EDatabaseName.CSM)))
    ) {
      return EDatabaseName.CSM;
    } else if (
      availableDB.length > 1 &&
      ((!isUserFromIframBasedOnWindowObject && availableDB.includes(EDatabaseName.Legacy_Database)) ||
        (userDomainName === EDomain.FULL_IHS_DOMAIN && availableDB.includes(EDatabaseName.Legacy_Database)))
    ) {
      return EDatabaseName.Legacy_Database;
    } else {
      return EDatabaseName.Legacy_Database;
    }
  }

  if (selectedDB && Object.values(EDatabaseName).includes(selectedDB)) {
    return selectedDB;
  } else if (
    availableDB.length > 1 &&
    ((isUserFromIframBasedOnWindowObject && availableDB.includes(EDatabaseName.CSM)) ||
      (userDomainName === EDomain.FULL_SP_DOMAIN && availableDB.includes(EDatabaseName.CSM)))
  ) {
    return EDatabaseName.CSM;
  } else if (
    availableDB.length > 1 &&
    ((!isUserFromIframBasedOnWindowObject && availableDB.includes(EDatabaseName.Legacy_Database)) ||
      (userDomainName === EDomain.FULL_IHS_DOMAIN && availableDB.includes(EDatabaseName.Legacy_Database)))
  ) {
    return EDatabaseName.Legacy_Database;
  } else if (availableDB.includes(EDatabaseName.Legacy_Database)) {
    return EDatabaseName.Legacy_Database;
  } else {
    return EDatabaseName.CSM;
  }
};

export const LAST_TOKEN_REFRESH_DATE = 'lastTokenRefreshDate';
