import { lazyLoad } from 'utils/loadable';

export const DatabaseAccessPage = lazyLoad(
  () => import('app/components/pages/DatabaseAccessPage/index'),
  (module) => module.DatabaseAccessPage,
);

export const DatabaseAccessPageProxyPage = lazyLoad(
  () => import('app/components/pages/DatabaseAccessPage/DatabaseAccessPageProxyPage'),
  (module) => module.DatabaseAccessPageProxyPage,
);
