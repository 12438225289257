import { datetimeMomentFormat } from 'constants/Dates';

import { InputLabel, TextField } from '@material-ui/core';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import { Moment } from 'moment';
import { ReactNode, useCallback } from 'react';

import { DateRangeValue } from 'app/components/containers/controls/DateRangeFilter/index';

import { useStyles } from './styles';
import { StyleClassName, StyleClassNameType } from './types';

export interface DatePickerFieldProps extends FieldProps, DatePickerProps {
  label: string;
  placeholder: string;
  onBlur: () => void;
  validateDatePickerValue: (values: DateRangeValue) => Record<'startDate' | 'endDate', string | undefined>;
  className?: StyleClassNameType;
}

export function DatePickerField({
  form,
  field: { value, name },
  placeholder,
  label,
  validateDatePickerValue,
  className = StyleClassName.DATE_FIELD,
}: DatePickerFieldProps) {
  const classes = useStyles();
  const errorMessage = form.errors[name];
  const showError = Boolean(errorMessage && form.touched[name]);
  const styleClass = classes[className];

  const handleOnChange = useCallback(
    (date: Moment | null, rawDate?: string) => {
      const normalizedDate =
        name === 'startDate'
          ? date?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) || null
          : date?.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }) || null;

      form.setFieldValue(name, normalizedDate, false);

      const validationErrors = validateDatePickerValue({
        ...form.values,
        [name]: normalizedDate,
      });
      const isFormValid = Object.values(validationErrors).some((error) => error !== undefined);

      if (isFormValid) {
        form.setErrors(validationErrors);
      } else {
        form.submitForm();
      }

      // To show error message if the entered date is completely filled
      if (rawDate && rawDate.length >= datetimeMomentFormat.length) {
        form.setFieldTouched(name, true, false);
      }
    },
    [form, name, validateDatePickerValue],
  );

  return (
    <DatePicker
      disableOpenPicker
      value={value}
      onChange={handleOnChange}
      allowSameDateSelection
      renderInput={(props) => (
        <div>
          <InputLabel className={classes.label}>{label}</InputLabel>
          <TextField
            hiddenLabel
            fullWidth={false}
            {...props}
            error={showError}
            inputProps={{
              ...props.inputProps,
              placeholder: placeholder,
            }}
            variant="outlined"
            helperText={showError ? (errorMessage as ReactNode) : null}
            className={styleClass}
            onBlur={() => form.setFieldTouched(name, true, false)}
            InputProps={{ classes: { root: classes.dateFieldSetRoot, notchedOutline: classes.dateFieldSet } }}
          />
        </div>
      )}
    />
  );
}
