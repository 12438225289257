import { AppRoute } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { LoadingOverlay } from 'impact-react-components';
import { ComponentType, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { EDatabaseName } from 'helpers/constants';
import { translations } from 'locales/i18n';
import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';
import { SELECTED_DATABASE } from 'utils/cookie';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { getCallingUrl } from 'utils/routes';

import { userInfoSaga } from './saga';
import {
  selectIsAuthenticated,
  selectIsAuthorizedSuccessfully,
  selectIsForbidden,
  selectIsInternalServerError,
  selectIsUnauthorized,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

export type PrivateRouteProps = RouteProps & {
  component: ComponentType<any> | ComponentType<any>;
  layout?: ComponentType<any>;
  testId?: TestLocators;
};

export function PrivateRoute({ component: Component, layout: Layout, testId }: PrivateRouteProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthorizedSuccessfully = useSelector(selectIsAuthorizedSuccessfully);
  const isForbidden = useSelector(selectIsForbidden);
  const isUnauthorized = useSelector(selectIsUnauthorized);
  const cookies = useMemo(() => new Cookies(), []);
  const isInternalServerError = useSelector(selectIsInternalServerError);

  useEffect(() => {
    if (!isAuthenticated) {
      const cookieValue = cookies.get(SELECTED_DATABASE);
      dispatch(actions.setSelectedDatabase(cookieValue as EDatabaseName));
      dispatch(actions.loadUserInfo());
    }
  }, [cookies, dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthorizedSuccessfully && isAuthenticated && !isForbidden)
      log.info({
        event: LogEvent.LOGIN,
        source: LogSource.LOGIN_PAGE,
      });
  }, [isAuthenticated, isAuthorizedSuccessfully, isForbidden]);

  if (isAuthenticated) {
    if (isAuthorizedSuccessfully) {
      if (Layout) {
        return (
          <Layout testId={testId}>
            <Component />
          </Layout>
        );
      } else {
        <Component />;
      }
    } else {
      if (isForbidden) {
        <Navigate to={AppRoute.FORBIDDEN} />;
      } else if (isInternalServerError) {
        <Navigate to={AppRoute.ERROR} />;
      } else if (isUnauthorized) {
        if (window.location.search.includes('code=')) {
          window.location.href = `${window.location.origin}${AppRoute.OKTA_LOGIN_PAGE}${window.location.search}`;
        } else {
          window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}/login?callingUrl=${encodeURIComponent(
            getCallingUrl(),
          )}`;
        }
      } else {
        if (window.location.search.includes('code=')) {
          window.location.href = `${window.location.origin}${AppRoute.OKTA_LOGIN_PAGE}${window.location.search}`;
        } else {
          window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}`;
        }
      }

      return <></>;
    }
  } else {
    return <LoadingOverlay message={t(translations.COMMON.LOADING)} />;
  }

  return <Navigate to={AppRoute.ROOT} replace={true} />;
}

export function usePrivateRoute() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userInfoSaga });
}
