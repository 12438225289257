import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import {
  BulkCreationPayload,
  LoadScenariosPayload,
  ScenarioByIdRequestPayload,
  ScenarioCreateRequestPayload,
  ScenarioRenameRequestPayload,
  ScenarioSavingResponse,
  ScenarioSharingRequestPayload,
  ScenarioUpdateRequestPayload,
} from 'api/scenarios';

import {
  buildMapWellsSettings,
  resetScenarioStateToInitPoint,
} from 'app/components/containers/layout/ConfigTabs/Scenario/utils';
import { cloneThroughJson } from 'helpers/objects';
import { ScenariosPermission } from 'types/Scenario';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  getDefaultScenarioSettingsToLoadOnMap,
  getScenarioSavedDataDefaultState,
  getUnloadableScenarioSettingsToLoadOnMap,
} from './config';
import { DEFAULT_SCENARIO_NAME } from './constants';
import {
  EScenarioType,
  Scenario,
  ScenarioDetails,
  ScenarioSavingData,
  ScenarioSharingObjectError,
  ScenariosState,
  TOptionalScenarioSavingData,
} from './types';

export const initialState: ScenariosState = {
  firstRunSelectedScenario: true,
  scenarioType: EScenarioType.EMPTY,
  scenariosLoadingStatus: RequestStatus.IDLE,
  sharingStatus: RequestStatus.IDLE,
  scenarioLoadingStatus: RequestStatus.IDLE,
  creatingStatus: RequestStatus.IDLE,
  creatingError: null,
  updatingStatus: RequestStatus.IDLE,
  updatingError: null,
  copyingStatus: RequestStatus.IDLE,
  copyingError: null,
  deletingStatus: RequestStatus.IDLE,
  deletingError: null,
  renamingStatus: RequestStatus.IDLE,
  renamingError: null,
  scenarioListLoadingError: null,
  sharingError: null,
  scenarioLoadingError: null,
  scenarios: [],
  scenarioSavingFormActive: false,
  scenarioRenamingInPanel: false,
  scenarioRenamingInHeader: false,
  dataForSaving: getScenarioSavedDataDefaultState(),
  lastSavedData: getScenarioSavedDataDefaultState(),
  scenarioDetailsId: null,
  scenarioPermission: ScenariosPermission.OWNER,
  scenarioSettingsToLoadOnMap: getDefaultScenarioSettingsToLoadOnMap(),
  name: DEFAULT_SCENARIO_NAME,
  previousVersionDatetime: null,
  scenarioSelected: null,
  hasToShowAllScenarios: false,
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    loadScenarios(state, _action: PayloadAction<LoadScenariosPayload>) {
      state.scenariosLoadingStatus = RequestStatus.LOADING;
      state.scenarioListLoadingError = null;
    },
    changeScenarioType(state, action: PayloadAction<EScenarioType>) {
      state.scenarioType = action.payload;
      state.creatingError = null;
      state.creatingStatus = RequestStatus.IDLE;
    },
    scenariosLoaded(state, { payload: scenarios }: PayloadAction<Scenario[]>) {
      state.scenariosLoadingStatus = RequestStatus.SUCCESS;
      state.scenarios = scenarios;
    },
    resetScenarios(state) {
      state.scenarios = [];
    },
    scenariosError(state, action: PayloadAction<string>) {
      state.scenariosLoadingStatus = RequestStatus.FAILURE;
      state.scenarioListLoadingError = action.payload;
    },
    createScenario(state, _action: PayloadAction<ScenarioCreateRequestPayload>) {
      state.creatingStatus = RequestStatus.LOADING;
      state.creatingError = null;
    },
    createBulk(state, _action: PayloadAction<BulkCreationPayload>) {
      state.creatingStatus = RequestStatus.LOADING;
      state.creatingError = null;
    },
    bulkCreated(state) {
      state.creatingStatus = RequestStatus.SUCCESS;
    },
    scenarioCreated(state, action: PayloadAction<ScenarioSavingResponse>) {
      state.creatingStatus = RequestStatus.SUCCESS;
      state.scenarioDetailsId = action.payload.detailsId;
      state.scenarioPermission = ScenariosPermission.OWNER;
      state.scenarioSavingFormActive = false;
      state.lastSavedData = cloneThroughJson(state.dataForSaving);
      state.name = action.payload.name;
      state.scenarioSettingsToLoadOnMap = getUnloadableScenarioSettingsToLoadOnMap();
      state.previousVersionDatetime = action.payload.lastUpdateDatetime;
    },
    createScenarioError(state, action: PayloadAction<string>) {
      state.creatingStatus = RequestStatus.FAILURE;
      state.creatingError = action.payload;
    },
    clearCreateScenarioError(state) {
      state.creatingStatus = RequestStatus.IDLE;
      state.creatingError = null;
    },
    updateScenario(state, _action: PayloadAction<ScenarioUpdateRequestPayload>) {
      state.updatingStatus = RequestStatus.LOADING;
      state.updatingError = null;
    },
    scenarioUpdated(state, action: PayloadAction<ScenarioSavingResponse>) {
      state.updatingStatus = RequestStatus.SUCCESS;
      state.scenarioSavingFormActive = false;
      state.lastSavedData = cloneThroughJson(state.dataForSaving);
      state.name = action.payload.name;
      state.scenarioSettingsToLoadOnMap = getUnloadableScenarioSettingsToLoadOnMap();
      state.previousVersionDatetime = action.payload.lastUpdateDatetime;
    },
    updateScenarioError(state, action: PayloadAction<unknown>) {
      state.updatingStatus = RequestStatus.FAILURE;
      state.updatingError = action.payload;
    },
    clearUpdateScenarioError(state) {
      state.updatingStatus = RequestStatus.IDLE;
      state.updatingError = null;
    },
    loadScenario(state, _action: PayloadAction<ScenarioByIdRequestPayload>) {
      state.scenarioLoadingStatus = RequestStatus.LOADING;
      state.scenarioLoadingError = null;
    },
    setScenario(
      state,
      {
        payload: { name, detailsId, details, permission },
      }: PayloadAction<{
        name: string;
        detailsId: number;
        permission: ScenariosPermission;
        details: ScenarioDetails;
      }>,
    ) {
      const savedData: ScenarioSavingData = {
        wells: cloneThroughJson(details.wells.items),
        mapSettings: cloneThroughJson(details.mapSettings),
        prodCurvesSettings: cloneThroughJson(details.prodCurvesSettings),
        impactCurvesSettings: cloneThroughJson(details.impactCurvesSettings),
        economicsParameters: state.dataForSaving.economicsParameters,
      };

      if (details.economicsParameters && details.economicsParameters.economicVariables.current) {
        savedData.economicsParameters = cloneThroughJson({
          wellDesignCapex: details.economicsParameters.wellDesignCapex,
          wellIdentification: details.economicsParameters.wellIdentification,
          economicVariables: { current: { ...details.economicsParameters.economicVariables.current } },
        });
      }
      state.name = name;
      state.scenarioDetailsId = detailsId;
      state.scenarioPermission = permission;
      state.dataForSaving = savedData;
      state.lastSavedData = savedData;
      state.previousVersionDatetime = details.dateUpdated;
      state.scenarioSettingsToLoadOnMap = buildMapWellsSettings(details.wells.items);
    },
    resetScenario(state) {
      state.name = DEFAULT_SCENARIO_NAME;
      state.scenarioDetailsId = null;
      state.scenarioPermission = ScenariosPermission.OWNER;
      state.dataForSaving = getScenarioSavedDataDefaultState();
      state.lastSavedData = getScenarioSavedDataDefaultState();
      state.previousVersionDatetime = null;
      state.scenarioSettingsToLoadOnMap = getDefaultScenarioSettingsToLoadOnMap();
    },
    scenarioLoaded(state) {
      state.scenarioLoadingStatus = RequestStatus.SUCCESS;
    },
    loadScenarioError(state, action: PayloadAction<unknown>) {
      state.scenarioLoadingStatus = RequestStatus.FAILURE;
      state.scenarioLoadingError = action.payload;
    },
    clearLoadScenarioError(state) {
      state.scenarioLoadingStatus = RequestStatus.IDLE;
      state.scenarioLoadingError = null;
    },
    startNewEmptyScenarioSaving(state) {
      state.scenarioSavingFormActive = true;
      resetScenarioStateToInitPoint(state);
    },
    startNewEmptyScenario(state) {
      resetScenarioStateToInitPoint(state);
    },
    setIsScenarioSaving(state, action: PayloadAction<boolean>) {
      state.scenarioSavingFormActive = action.payload;
    },
    setFirstRunSelectedScenario(state, action: PayloadAction<boolean>) {
      state.firstRunSelectedScenario = action.payload;
    },
    setDataForSaving(state, action: PayloadAction<TOptionalScenarioSavingData>) {
      if (action.payload.scenarioType === EScenarioType.PLAN) {
        const { wells, mapSettings, prodCurvesSettings, impactCurvesSettings } = action.payload;
        if (wells && mapSettings && prodCurvesSettings && impactCurvesSettings)
          state.dataForSaving = {
            wells,
            mapSettings,
            prodCurvesSettings,
            impactCurvesSettings,
            economicsParameters: state.dataForSaving.economicsParameters,
          };
      }
      if (action.payload.scenarioType === EScenarioType.ECONOMICS) {
        const { economicsParameters } = action.payload;
        if (economicsParameters)
          state.dataForSaving = {
            wells: state.dataForSaving.wells,
            mapSettings: state.dataForSaving.mapSettings,
            prodCurvesSettings: state.dataForSaving.prodCurvesSettings,
            impactCurvesSettings: state.dataForSaving.impactCurvesSettings,
            economicsParameters: {
              economicVariables: {
                ...state.dataForSaving.economicsParameters?.economicVariables,
                ...economicsParameters.economicVariables,
              },
              wellDesignCapex: {
                ...state.dataForSaving.economicsParameters?.wellDesignCapex,
                ...economicsParameters.wellDesignCapex,
              },
              wellIdentification: {
                ...state.dataForSaving.economicsParameters?.wellIdentification,
                ...economicsParameters.wellIdentification,
              },
            },
          };
      }
    },
    setLastSavedData(state, action: PayloadAction<TOptionalScenarioSavingData>) {
      if (action.payload.scenarioType === EScenarioType.PLAN) {
        const { wells, mapSettings, prodCurvesSettings, impactCurvesSettings } = action.payload;
        if (wells && mapSettings && prodCurvesSettings && impactCurvesSettings)
          state.lastSavedData = {
            wells,
            mapSettings,
            prodCurvesSettings,
            impactCurvesSettings,
            economicsParameters: state.dataForSaving.economicsParameters,
          };
      }
      if (action.payload.scenarioType === EScenarioType.ECONOMICS) {
        const { economicsParameters } = action.payload;
        if (economicsParameters)
          state.lastSavedData = {
            wells: state.dataForSaving.wells,
            mapSettings: state.dataForSaving.mapSettings,
            prodCurvesSettings: state.dataForSaving.prodCurvesSettings,
            impactCurvesSettings: state.dataForSaving.impactCurvesSettings,
            economicsParameters: {
              economicVariables: {
                ...state.dataForSaving.economicsParameters?.economicVariables,
                ...economicsParameters.economicVariables,
              },
              wellDesignCapex: {
                ...state.dataForSaving.economicsParameters?.wellDesignCapex,
                ...economicsParameters.wellDesignCapex,
              },
              wellIdentification: {
                ...state.dataForSaving.economicsParameters?.wellIdentification,
                ...economicsParameters.wellIdentification,
              },
            },
          };
      }
    },
    setScenarioName(state, { payload }: PayloadAction<string>) {
      state.name = payload;
    },
    markCurrentDataForSavingAsSaved(state) {
      state.lastSavedData = cloneThroughJson(state.dataForSaving);
    },
    copyScenario(state, _action: PayloadAction<ScenarioByIdRequestPayload>) {
      state.copyingStatus = RequestStatus.LOADING;
      state.copyingError = null;
    },
    scenarioCopied(state) {
      state.copyingStatus = RequestStatus.SUCCESS;
    },
    copyScenarioError(state, action: PayloadAction<unknown>) {
      state.copyingStatus = RequestStatus.FAILURE;
      state.copyingError = action.payload;
    },
    clearCopyScenarioError(state) {
      state.copyingError = RequestStatus.IDLE;
      state.copyingError = null;
    },
    deleteScenario(state, _action: PayloadAction<ScenarioByIdRequestPayload>) {
      state.deletingStatus = RequestStatus.LOADING;
      state.deletingError = null;
    },
    scenarioDeleted(state) {
      state.deletingStatus = RequestStatus.SUCCESS;
    },
    deleteScenarioError(state, action: PayloadAction<string>) {
      state.deletingStatus = RequestStatus.FAILURE;
      state.deletingError = action.payload;
    },
    shareScenario(state, _action: PayloadAction<ScenarioSharingRequestPayload>) {
      state.sharingStatus = RequestStatus.LOADING;
      state.sharingError = null;
    },
    scenarioShared(state) {
      state.sharingStatus = RequestStatus.SUCCESS;
    },
    shareScenarioError(state, action: PayloadAction<string | ScenarioSharingObjectError>) {
      state.sharingStatus = RequestStatus.FAILURE;
      state.sharingError = action.payload;
    },
    clearShareError(state) {
      state.sharingStatus = RequestStatus.IDLE;
      state.sharingError = null;
    },
    renameScenario(state, _action: PayloadAction<ScenarioRenameRequestPayload>) {
      state.renamingStatus = RequestStatus.LOADING;
      state.renamingError = null;
    },
    scenarioRenamed(state) {
      state.renamingStatus = RequestStatus.SUCCESS;
      state.scenarioRenamingInPanel = false;
      state.scenarioRenamingInHeader = false;
    },
    renameScenarioError(state, action: PayloadAction<string>) {
      state.renamingStatus = RequestStatus.FAILURE;
      state.renamingError = action.payload;
    },
    clearRenameScenarioError(state) {
      state.renamingStatus = RequestStatus.IDLE;
      state.renamingError = null;
    },
    setIsScenarioRenamingInPanel(state, action: PayloadAction<boolean>) {
      state.scenarioRenamingInPanel = action.payload;
    },
    setIsScenarioRenamingInHeader(state, action: PayloadAction<boolean>) {
      state.scenarioRenamingInHeader = action.payload;
    },
    resetLoadedScenarioData(state) {
      state.scenarioSettingsToLoadOnMap = getUnloadableScenarioSettingsToLoadOnMap();
      state.dataForSaving = getScenarioSavedDataDefaultState();
      state.lastSavedData = getScenarioSavedDataDefaultState();
    },
    scenarioSelected(state, _action: PayloadAction<number>) {
      state.scenarioSelected = _action.payload;
    },
    shownAllScenariosSelected(state) {
      state.hasToShowAllScenarios = true;
    },
    allScenarioShown(state) {
      state.hasToShowAllScenarios = false;
    },
  },
});

export const createBulkType = actions.createBulk.type;
