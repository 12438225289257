import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import { ESuggestionsQuerry } from 'api/filter';

import { EMapWidget } from 'app/components/common/MapWidgetContainer/constants';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { WellInfoData, WellInfoWidgetState } from './types';

export const initialState: WellInfoWidgetState = {
  status: RequestStatus.IDLE,
  error: null,
  predict: {
    data: null,
  },
  economics: {
    data: null,
  },
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'wellInfoWidget',
  initialState,
  reducers: {
    loadWellInfo(state, _action: PayloadAction<{ uwi: string; type: EMapWidget; queryType: ESuggestionsQuerry }>) {
      state.status = RequestStatus.LOADING;
      state.error = null;
    },
    wellInfoLoaded(state, action: PayloadAction<{ data: WellInfoData; type: EMapWidget }>) {
      state.status = RequestStatus.SUCCESS;
      state[action.payload.type].data = action.payload.data;
    },
    resetWellInfo() {
      return { ...initialState };
    },
    wellInfoError(state, action: PayloadAction<{ error: string; type: EMapWidget }>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload.error;
      state[action.payload.type].data = null;
    },
    clearWellInfoError(state) {
      state.error = null;
      state.status = RequestStatus.IDLE;
    },
  },
});
