import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';

import { initialState } from './slice';
import {
  NumberWellDesignCapexCalculateParameter,
  NumberWellDesignCapexManualParameter,
  WellDesignCapexSection,
} from './types';

export const wellDesignCapexDomain = (state: RootState) => state?.wellDesignCapex || initialState;

export const selectwellDesignCapexData = createSelector([wellDesignCapexDomain], (state) => state);

export const selectwellDesignCapexDataForSaving = createSelector([wellDesignCapexDomain], (state) => {
  const { calculate, manual, wellDesignCapexErrors, spEstimate } = state;

  return { calculate, manual, wellDesignCapexErrors, spEstimate };
});

export const selectWellOrientation = createSelector(
  [wellDesignCapexDomain],
  (state) => state.calculate.current.wellOrientation,
);
export const selectActiveTab = createSelector([wellDesignCapexDomain], (state) => state.activeTab);
export const selectWellDesignCapexErrors = createSelector(
  [wellDesignCapexDomain],
  (state) => state.wellDesignCapexErrors,
);

export const selectWellDesignCapexCalculateVariable = createSelector(
  [wellDesignCapexDomain, (state: RootState, id: NumberWellDesignCapexCalculateParameter) => id],
  (state, id) => state.calculate.current[id],
);
export const selectWellDesignCapexCalculateOptions = createSelector(
  [wellDesignCapexDomain, (state: RootState, id: NumberWellDesignCapexCalculateParameter) => id],
  (state, id) => state.calculate.options[id],
);

export const selectWellVintageDate = createSelector(
  [wellDesignCapexDomain],
  (state) => state.calculate.current.wellVintageDate,
);

export const selectSpEstimate = createSelector([wellDesignCapexDomain], (state) => state.spEstimate);
export const selectManualCapexValue = createSelector(
  [wellDesignCapexDomain],
  (state) => state.manual.current.manualCapex,
);
export const selectManualCapexOptions = createSelector(
  [wellDesignCapexDomain],
  (state) => state.manual.options.manualCapex,
);

export const selectWellDesignCapexManualVariable = createSelector(
  [wellDesignCapexDomain, (state: RootState, id: NumberWellDesignCapexManualParameter) => id],
  (state, id) => state[WellDesignCapexSection.MANUAL].current[id],
);

export const selectWellDesignCapexManualOptions = createSelector(
  [wellDesignCapexDomain, (state: RootState, id: NumberWellDesignCapexManualParameter) => id],
  (state, id) => state[WellDesignCapexSection.MANUAL].options[id],
);
