import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;

  return createStyles({
    overlayWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      backgroundColor: type === ThemeMode.DARK ? 'rgba(37,37,37,0.5)' : 'rgba(255,255,255,0.5)',
      zIndex: 3, // for map controls
    },
  });
});
