import { PayloadAction } from '@reduxjs/toolkit';

import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { MapWidgetId } from 'types/Widget';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { MapTooltipState, MapWidgetsTooltipState, TooltipItemChooseActionPayloadType } from './types';

const initialMapTooltipState: MapTooltipState = [
  ImpactWellAndProdColumn.UWI_DIGIT,
  ImpactWellAndProdColumn.OPERATOR,
  ImpactWellAndProdColumn.PLAY_DESIGNATION,
  ImpactWellAndProdColumn.WELL_NAME,
  ImpactWellAndProdColumn.STATUS_WELL,
];

export const initialState: MapWidgetsTooltipState = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: initialMapTooltipState,
  [MapWidgetId.ECONOMICS_MAP_WIDGET]: initialMapTooltipState,
  [MapWidgetId.PLAN_MAP_WIDGET]: initialMapTooltipState,
};

const mapWidgetsTooltipSlice = createSlice({
  name: 'mapWidgetsTooltip',
  initialState,
  reducers: {
    setTooltipItems(
      state,
      {
        payload: { mapWidgetId, columnNames },
      }: PayloadAction<{
        mapWidgetId: MapWidgetId;
        columnNames: ImpactWellAndProdColumn[];
      }>,
    ) {
      state[mapWidgetId] = columnNames;
    },
    changeValue(
      state,
      { payload: { mapWidgetId, add, columnName } }: PayloadAction<TooltipItemChooseActionPayloadType>,
    ) {
      const index = state[mapWidgetId].indexOf(columnName);
      if (index > -1) {
        if (!add) {
          state[mapWidgetId].splice(index, 1);
        }
      } else if (add) {
        state[mapWidgetId].push(columnName);
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsTooltipSlice;
