import { TestLocators } from 'constants/Locators';

import { EconomicVariableSliderWithTextInput } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/common/EconomicVariableSliderWithTextInput';

import { useGetWellDesignCapexData } from './config';
import { WellOrientation } from './WellOrientation';
import { WellVintageDate } from './WellVintageDate';

export function Calculate() {
  const wellDesignCapexData = useGetWellDesignCapexData();

  return (
    <div data-testid={TestLocators.Economics_ConfigPanel_EconomicsControl_WellDesignCapex_Calculate}>
      <WellOrientation />
      {wellDesignCapexData.map(({ label, testId, value, options, onChangeHandler }) => {
        return (
          <EconomicVariableSliderWithTextInput
            key={testId}
            label={label}
            testId={testId}
            value={value}
            options={options}
            onChange={onChangeHandler}
          />
        );
      })}
      <WellVintageDate />
    </div>
  );
}
