import { TestLocators } from 'constants/Locators';

import { Field, Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DatePickerField } from 'app/components/containers/controls/DateRangeFilter/DateRangeField';
import { StyleClassName } from 'app/components/containers/controls/DateRangeFilter/DateRangeField/types';
import { selectWellVintageDate } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/selectors';
import { actions as wellDesignCapexActions } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { translations } from 'locales/i18n';
import { DateFormatType } from 'types/DateFormatType';

export interface DateRangeValue {
  wellVintageDate: Moment | null;
}

export function WellVintageDate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validateDatePickerValue = useCallback(
    (t: TFunction) =>
      ({ wellVintageDate }: { wellVintageDate: Moment | null }) => {
        const errors: Record<'wellVintageDate', string | undefined | null> = {
          wellVintageDate: undefined,
        };

        if (wellVintageDate === null) {
          errors.wellVintageDate = t(translations.VALIDATION_MESSAGES.REQUIRED);
        }

        if (wellVintageDate !== null) {
          if (!wellVintageDate.isValid()) {
            errors.wellVintageDate = t('VALIDATION_MESSAGES.DATE_INCORRECT');
          } else if (wellVintageDate.isAfter(moment(), 'day')) {
            errors.wellVintageDate = t('VALIDATION_MESSAGES.DATE_NOT_BIGGER_THEN_TODAY');
          }
        }

        dispatch(wellDesignCapexActions.changeWellVintageDateError({ error: errors.wellVintageDate ?? null }));

        return errors;
      },
    [dispatch],
  );
  const validate = useMemo(() => validateDatePickerValue(t), [t, validateDatePickerValue]);
  const wellVintageDate = useSelector(selectWellVintageDate);

  const handleSubmit = useCallback(
    ({ wellVintageDate }: DateRangeValue) => {
      dispatch(
        wellDesignCapexActions.changeWellVintageDate(
          wellVintageDate === null
            ? { wellVintageDate: null }
            : { wellVintageDate: wellVintageDate?.format(DateFormatType.DEFAULT_FORMAT) },
        ),
      );
    },
    [dispatch],
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{
        wellVintageDate: moment(wellVintageDate) ?? null,
      }}
    >
      <Form
        className={''}
        data-testid={TestLocators.WellDesignCapex_Calculate_WellVintageDate}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Field
          name="wellVintageDate"
          label={t('CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.WELL_VINTAGE_DATE.TITLE')}
          placeholder={'mm/dd/yyyy'}
          component={DatePickerField}
          validateDatePickerValue={validate}
          className={StyleClassName.FULL_WIDTH}
        />
      </Form>
    </Formik>
  );
}
