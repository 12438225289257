import { ComponentType } from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

export type CommonRouteProps = RouteProps & {
  component: ComponentType<any> | ComponentType<any>;
  layout?: ComponentType<any>;
};

export function CommonRoute({ component: Component, layout: Layout, ...rest }: CommonRouteProps) {
  return (
    <Route
      {...rest}
      element={
        <>
          {(props: any) =>
            Layout ? (
              <Layout>
                <Component {...props} />
              </Layout>
            ) : (
              <Component {...props} />
            )
          }
        </>
      }
    />
  );
}
