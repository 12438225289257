import { TestLocators } from 'constants/Locators';

import { Box, BoxProps, Tooltip, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import cn from 'clsx';
import { MouseEvent, useCallback } from 'react';

import { useStyles } from './styles';

export interface HeaderItemProps extends BoxProps {
  label?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  showDropDownIcon?: boolean;
  showBorder?: boolean;
  tooltipText?: string;
  testId?: TestLocators;
  isPriceFeed?: boolean;
}

export const HeaderItem = ({
  className,
  label,
  labelClassName,
  disabled = false,
  readOnly = false,
  isPriceFeed = false,
  showDropDownIcon = false,
  showBorder = false,
  tooltipText = '',
  testId,
  onClick,
  ...props
}: HeaderItemProps) => {
  const classes = useStyles({ isPriceFeed });
  const tooltipOpenProp = tooltipText ? undefined : false;
  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => (disabled ? undefined : onClick && onClick(event)),
    [disabled, onClick],
  );

  return (
    <Box
      className={cn(className, classes.headerItem, classes.basicColors, {
        [classes.disabled]: disabled,
        [classes.readOnly]: readOnly,
      })}
      onClick={handleClick}
      {...props}
    >
      <Tooltip title={tooltipText} arrow open={tooltipOpenProp}>
        <Typography className={cn(labelClassName, classes.label)} variant="h3" data-testid={testId}>
          {label}
          {showDropDownIcon && <ArrowDropDownIcon className={classes.arrowIcon} color="inherit" />}
        </Typography>
      </Tooltip>
      {showBorder && <Box className={classes.animatedBorder} />}
    </Box>
  );
};
