import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { EWellDesignCapexOption } from './config';
import {
  NumberWellDesignCapexCalculateParameter,
  NumberWellDesignCapexManualParameter,
  TWellDesignCapexError,
  WellDesignCapexState,
} from './types';

export const AVAILABLE_WELL_ORIENTATION_OPTIONS = ['Horizontal', 'Vertical'];

export const initialState: WellDesignCapexState = {
  calculate: {
    current: {
      wellOrientation: 'Horizontal',
      trueVerticalDepth: 10000,
      lateralLength: 10000,
      proppantVolume: 3200,
      wellVintageDate: '2021-12-10T23:59:59',
      wellVintageDateError: null,
    },
    options: {
      wellOrientation: AVAILABLE_WELL_ORIENTATION_OPTIONS,
      trueVerticalDepth: { min: 0, max: 20000 },
      lateralLength: { min: 0, max: 20000 },
      proppantVolume: { min: 0, max: 6400 },
    },
  },
  manual: {
    current: {
      manualCapex: 5000000,
    },
    options: {
      manualCapex: { min: 0, max: 25000000 },
    },
  },
  wellDesignCapexErrors: [],
  spEstimate: '9200000000',
  activeTab: EWellDesignCapexOption.CALCULATE,
};

const wellDesignCapexSlice = createSlice({
  name: 'wellDesignCapex',
  initialState,
  reducers: {
    setWellDesignCapexData(state, { payload }: PayloadAction<Omit<WellDesignCapexState, 'activeTab'>>) {
      return { ...state, ...payload };
    },
    changeWellOrientation(state, { payload: { wellOrientation } }: PayloadAction<{ wellOrientation: string }>) {
      state.calculate.current.wellOrientation = wellOrientation;
    },
    changeWellVintageDate(state, { payload: { wellVintageDate } }: PayloadAction<{ wellVintageDate: string | null }>) {
      state.calculate.current.wellVintageDate = wellVintageDate;
    },
    changeWellVintageDateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.calculate.current.wellVintageDateError = error;
    },
    changeWellDesignCapexErrors(
      state,
      { payload: { errors } }: PayloadAction<{ errors: Array<TWellDesignCapexError> }>,
    ) {
      state.wellDesignCapexErrors = errors;
    },
    changeManualCapex(state, { payload: { manualCapex } }: PayloadAction<{ manualCapex: number }>) {
      state.manual.current.manualCapex = manualCapex;
    },
    changeActiveTab(state, { payload: { activeTab } }: PayloadAction<{ activeTab: string }>) {
      state.activeTab = activeTab;
    },
    setWellDesignCapexCalculateNumberValue(
      state,
      {
        payload: { value, paramName },
      }: PayloadAction<{ value: number; paramName: NumberWellDesignCapexCalculateParameter }>,
    ) {
      state.calculate.current[paramName] = value;
    },
    setWellDesignCapexManualNumberValue(
      state,
      {
        payload: { value, paramName },
      }: PayloadAction<{ value: number; paramName: NumberWellDesignCapexManualParameter }>,
    ) {
      state.manual.current[paramName] = value;
    },
    resetWellDesignCapex() {
      return initialState;
    },
  },
});

export const { actions, reducer, name: sliceKey } = wellDesignCapexSlice;
