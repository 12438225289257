import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return createStyles({
    label: {
      display: 'block',
      lineHeight: '20px',
    },
    dateField: {
      width: 108,
    },
    dateFieldSetRoot: {
      '&:hover $dateFieldSet': {
        borderColor: 'transparent',
      },
    },
    dateFieldSet: {
      borderColor: 'transparent',
    },
    fullWidth: {
      width: '100%',
    },
  });
});
