import { OptionType } from '../EconomicVariables/types';

export enum StringWellDesignCapexControl {
  WELL_ORIENTATION = 'wellOrientation',
  WELL_VINTAGE_DATE = 'wellVintageDate',
  WELL_VINTAGE_DATE_ERROR = 'wellVintageDateError',
}

export enum NumberWellDesignCapexControl {
  TRUE_VERTICAL_DEPTH = 'trueVerticalDepth',
  LATERAL_LENGTH = 'lateralLength',
  PROPPANT_VOLUME = 'proppantVolume',
  MANUAL_CAPEX = 'manualCapex',
}

export type CalculateType = {
  current: {
    wellOrientation: string;
    trueVerticalDepth: number;
    lateralLength: number;
    proppantVolume: number;
    wellVintageDate: string | null;
    wellVintageDateError: string | null;
  };
  options: {
    wellOrientation: string[];
    trueVerticalDepth: OptionType;
    lateralLength: OptionType;
    proppantVolume: OptionType;
  };
};

export type ManualType = {
  current: {
    manualCapex: number;
  };
  options: {
    manualCapex: OptionType;
  };
};

export enum WellDesignCapexSection {
  MANUAL = 'manual',
  CALCULATE = 'calculate',
}

export type TWellDesignCapexError = {
  description: string;
};

export interface WellDesignCapexState {
  calculate: CalculateType;
  manual: ManualType;
  spEstimate: string;
  activeTab: string;
  wellDesignCapexErrors: Array<TWellDesignCapexError>;
}

export type NumberWellDesignCapexCalculateParameter =
  | NumberWellDesignCapexControl.TRUE_VERTICAL_DEPTH
  | NumberWellDesignCapexControl.LATERAL_LENGTH
  | NumberWellDesignCapexControl.PROPPANT_VOLUME;

export type StringWellDesignCapexCalculateParameter =
  | StringWellDesignCapexControl.WELL_VINTAGE_DATE_ERROR
  | StringWellDesignCapexControl.WELL_ORIENTATION;

export type NumberWellDesignCapexManualParameter = NumberWellDesignCapexControl.MANUAL_CAPEX;
