export function shouldBeShownAsIs(value: string, allowFractional: boolean) {
  return value === '' || (allowFractional && isNumberWithDotAtTheEnd(value));
}

function isNumberWithDotAtTheEnd(value: string) {
  return value.match(/(\d){1,}(.){1}/);
}

function replaceAllDotsExceptFirst(value: string) {
  const firstOccurrenceIndex = value.search(/\./) + 1; // Index of first Occurrence of (.)

  return value.slice(0, firstOccurrenceIndex) + value.slice(firstOccurrenceIndex).replace(/[.,]/g, '');
}

export function getValidNumberString(value: string, allowFractional: boolean) {
  if (allowFractional) {
    const dotsAndDigits = value.replace(/[^.0-9]/g, '');

    return replaceAllDotsExceptFirst(dotsAndDigits);
  } else {
    return value.replace(/[^0-9]/g, '');
  }
}

export const CreateRegex = (value: number | null) => new RegExp(`^\\d+(\\.\\d{0,${value}})?$`);
