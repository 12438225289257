import { RequestStatus } from 'constants/RequestStatus';

import { NonNullableKeys } from 'utils/types/non-nullable-keys';

export enum PlanWellParameterName {
  PLAY_DESIGNATION = 'playDesignation',
  RESERVOIR = 'reservoir',
  LATERAL_LENGTH = 'lateralLength',
  PROPPANT_PER_FT = 'proppantPerFt',
  PROPPANT_PER_FLUID_RATIO = 'proppantPerFluidRatio',
  NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING = 'nearestSameReservoirNeighborSpacing',
}

export const PLAY_DESIGNATION_LIST = 'playDesignationList';

export type WellParameterSliderSettings = {
  min: number;
  max: number;
  average: number;
};

export type PlanWellParametersOptions = {
  [PlanWellParameterName.PLAY_DESIGNATION]: string[];
  [PlanWellParameterName.RESERVOIR]: string[];
  [PlanWellParameterName.LATERAL_LENGTH]: WellParameterSliderSettings | null;
  [PlanWellParameterName.PROPPANT_PER_FT]: WellParameterSliderSettings | null;
  [PlanWellParameterName.PROPPANT_PER_FLUID_RATIO]: WellParameterSliderSettings | null;
  [PlanWellParameterName.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING]: WellParameterSliderSettings | null;
};

export interface PlanWellParameters {
  [PlanWellParameterName.PLAY_DESIGNATION]: string | null;
  [PlanWellParameterName.RESERVOIR]: string | null;
  [PlanWellParameterName.LATERAL_LENGTH]: number | null;
  [PlanWellParameterName.PROPPANT_PER_FT]: number | null;
  [PlanWellParameterName.PROPPANT_PER_FLUID_RATIO]: number | null;
  [PlanWellParameterName.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING]: number | null;
}

export type WellParametersWells = Record<number, NonNullableKeys<PlanWellParameters>>;
export interface PlanWellParametersState {
  status: RequestStatus;
  error: string | null;
  updateParameterOptionsStatus: RequestStatus;
  updateParameterOptionsError: string | null;
  currentPlay: string | null;
  options: PlanWellParametersOptions;
  current: PlanWellParameters;
  default: PlanWellParameters;
  wells: WellParametersWells;
}

export type StringPlanWellParameter = PlanWellParameterName.RESERVOIR | PlanWellParameterName.PLAY_DESIGNATION;
export type NumberPlanWellParameter =
  | PlanWellParameterName.LATERAL_LENGTH
  | PlanWellParameterName.PROPPANT_PER_FT
  | PlanWellParameterName.PROPPANT_PER_FLUID_RATIO
  | PlanWellParameterName.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING;
