import { RequestStatus } from 'constants/RequestStatus';

import { AxisIntervalsType } from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { PredictionModelsValue } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { PlanWellParameters } from 'app/components/containers/layout/ConfigTabs/PlannerFilter/WellParametersSection/types';
import { MapLayerSettings } from 'app/components/containers/map/MapLayersSelect/types';
import { WellSettings } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/types';
import { ColumnType } from 'types/ColumnType';
import { CurveType } from 'types/Curve';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { ScenariosPermission } from 'types/Scenario';
import { NonNullableKeys } from 'utils/types/non-nullable-keys';

import { TWellDesignCapexError } from '../EconomicsControl/WellDesignCapex/types';

export interface Scenario {
  id: number;
  name: string;
  permission: ScenariosPermission;
  isShared: boolean;
  ownerUserId: string;
}

export interface ScenarioSettingsToLoadOnMap {
  activeWellId: number | null;
  items: WellSettings[] | null;
}

export type ScenarioSharingObjectError = { value: string; messageKey: string };

export const isScenarioSharingObjectError = (error: any): error is ScenarioSharingObjectError => {
  return error && error.value !== undefined && error.messageKey;
};
export interface ScenariosState {
  firstRunSelectedScenario: boolean;
  scenarioType: EScenarioType;
  scenariosLoadingStatus: RequestStatus;
  sharingStatus: RequestStatus;
  scenarioLoadingStatus: RequestStatus;
  creatingStatus: RequestStatus;
  creatingError: string | null;
  updatingStatus: RequestStatus;
  updatingError: unknown | null;
  copyingStatus: RequestStatus;
  copyingError: unknown | null;
  deletingStatus: RequestStatus;
  deletingError: string | null;
  renamingStatus: RequestStatus;
  renamingError: string | null;
  scenarios: Scenario[];
  scenarioListLoadingError: string | null;
  sharingError: string | ScenarioSharingObjectError | null;
  scenarioLoadingError: unknown | null;
  scenarioSavingFormActive: boolean;
  scenarioRenamingInPanel: boolean;
  scenarioRenamingInHeader: boolean;
  dataForSaving: ScenarioSavingData;
  lastSavedData: ScenarioSavingData;
  scenarioDetailsId: number | null;
  scenarioPermission: ScenariosPermission;
  scenarioSettingsToLoadOnMap: ScenarioSettingsToLoadOnMap;
  name: string;
  previousVersionDatetime: string | null;
  scenarioSelected: number | null;
  hasToShowAllScenarios: boolean;
}

type TCommodityPriceObject = {
  key: string;
  value: string;
} | null;

type TOption = {
  min: number;
  max: number;
};
interface IEconomicVariables {
  current: {
    commodityPricesGas: TCommodityPriceObject;
    commodityPricesOil: TCommodityPriceObject;
    fixedOpex: number;
    variableOpex: number;
    inflationEscalationRate: number;
    royalty: number;
    cashTax: number;
    sevAdValTaxByRevenueOil: number;
    sevAdValTaxByRevenueGas: number;
    taxPerBblOil: number;
    taxPerMcfGas: number;
    simulateCashflowCutoff: boolean;
  };
  options: {
    fixedOpex: TOption;
    variableOpex: TOption;
    inflationEscalationRate: TOption;
    royalty: TOption;
    cashTax: TOption;
    sevAdValTaxByRevenueOil: TOption;
    sevAdValTaxByRevenueGas: TOption;
    taxPerBblOil: TOption;
    taxPerMcfGas: TOption;
  };
}

interface ICalculate {
  current: {
    wellOrientation: string;
    trueVerticalDepth: number;
    lateralLength: number;
    proppantVolume: number;
    wellVintageDate: string | null;
    wellVintageDateError: string | null;
  };
  options: {
    wellOrientation: string[];
    trueVerticalDepth: TOption;
    lateralLength: TOption;
    proppantVolume: TOption;
  };
}

interface IManual {
  current: {
    manualCapex: number;
  };
  options: {
    manualCapex: TOption;
  };
}

interface TWellDesignCapex {
  calculate: ICalculate;
  manual: IManual;
  spEstimate: string;
  wellDesignCapexErrors: Array<TWellDesignCapexError>;
}

type TWellIdentityParameter = string | number | null;
interface IWellIdentity {
  wellName: TWellIdentityParameter;
  wellNumber: TWellIdentityParameter;
  basin: TWellIdentityParameter;
  playDesignation: TWellIdentityParameter;
  reservoir: TWellIdentityParameter;
  statusCurrentProd: TWellIdentityParameter;
  dateVintage: TWellIdentityParameter;
  country: TWellIdentityParameter;
  Operator: TWellIdentityParameter;
}

interface IWellIdentification {
  uwi: string;
  wellIdentity: IWellIdentity;
}

export interface ScenarioDetailsEconomicsParameters<T = IEconomicVariables> {
  economicVariables: T;
  wellDesignCapex: TWellDesignCapex;
  wellIdentification: IWellIdentification;
}

export interface ScenarioSavingData {
  wells: WellSavingData[];
  mapSettings: MapSettingsSavingData;
  prodCurvesSettings: ProdCurvesSettingsSavingData;
  impactCurvesSettings: ImpactCurvesSettingsSavingData;
  economicsParameters?: ScenarioDetailsEconomicsParameters<Partial<IEconomicVariables>>;
}

export type TOptionalScenarioSavingData = Partial<ScenarioSavingData> & {
  scenarioType: EScenarioType;
};

export type SavingWellSettings = NonNullableKeys<
  Omit<WellSettings, 'tempLengthLimitPoint' | 'activePointType' | 'hidden'>
>;
export interface WellSavingData extends NonNullableKeys<PlanWellParameters>, SavingWellSettings {
  active: boolean;
  geologic_province: string;
}

export interface WellSearchData {
  id: number;
  name: string;
}

interface MapLayerSettingsSavingData extends Omit<MapLayerSettings, 'colorByValue'> {
  colorByField: ColumnType | null;
}

export interface MapSettingsSavingData {
  layerItems: MapLayerSettingsSavingData[];
  tooltipItems: ImpactWellAndProdColumn[];
}

export interface ProdCurvesSettingsSavingData {
  curveSelection: Record<CurveType, boolean>;
  cumulativeProduction: boolean;
  predictionModels: PredictionModelsValue;
  axis: AxisIntervalsType;
  labels: boolean;
  gridlines: boolean;
  timeSelection: boolean;
  aggregateProduction: boolean;
}

export interface ImpactCurvesSettingsSavingData {
  type: CurveType;
  duration: number;
  parameterValue: boolean;
}

export type ScenarioDetails = {
  dateCreated: string;
  dateUpdated: string;
  scenarioId: number;
  wells: {
    version: string;
    items: WellSavingData[];
  };
  mapSettings: MapSettingsSavingData & {
    version: string;
  };
  prodCurvesSettings: ProdCurvesSettingsSavingData & {
    version: string;
  };
  impactCurvesSettings: ImpactCurvesSettingsSavingData & {
    version: string;
  };
  economicsParameters: ScenarioDetailsEconomicsParameters;
};

export enum ScenarioValidationsErrorKey {
  SCENARIO_WITH_THIS_NAME_ALREADY_EXISTS = 'SCENARIO_WITH_THIS_NAME_ALREADY_EXISTS',
  USER_ID_SHOULD_BE_AN_EMAIL = 'USER_ID_SHOULD_BE_AN_EMAIL',
  USER_NOT_FOUND_FOR_SCENARIO_SHARING = 'USER_NOT_FOUND_FOR_SCENARIO_SHARING',
  SCENARIO_NAME_IS_TOO_LONG = 'SCENARIO_NAME_IS_TOO_LONG',
  SCENARIO_HAS_BEEN_ALREADY_CHANGED = 'SCENARIO_HAS_BEEN_ALREADY_CHANGED',
}

export enum EScenarioType {
  PLAN = 'plan',
  ECONOMICS = 'economics',
  EMPTY = '',
}
