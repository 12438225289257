import { TestLocators } from 'constants/Locators';

import {
  InputAdornment,
  InputLabel,
  TooltipProps as MuiTooltipProps,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import cn from 'clsx';
import { debounce } from 'debounce';
import { Tooltip } from 'impact-react-components';
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { Overlay } from 'app/components/common/Overlay';
import { CreateRegex, getValidNumberString, shouldBeShownAsIs } from 'app/components/common/SliderWithTextInput/utils';

import { useStyles } from './styles';
import { EAdornmentPlacement } from './types';

export interface SliderWithTextInputProps {
  label: string;
  value: number;
  minValue: number;
  maxValue: number;
  minLabel?: string;
  maxLabel?: string;
  allowFractional?: boolean;
  inputWidth?: string;
  step?: number;
  isLoading?: boolean;
  formatInputValue: (value: number) => string;
  onChange(value: number): void;
  testId?: TestLocators | string;
  disabled?: boolean;
  tooltipText?: string;
  tooltipProps?: Partial<MuiTooltipProps>;
  maxLength?: number;
  maximumDecimalPlaces?: number | null;
  adornmentSign?: string;
  adornmentPlacement?: EAdornmentPlacement;
}

export type SliderWithTextInputRef = {
  setValue: (value: number) => void;
};

export const SliderWithTextInput = forwardRef(
  (
    {
      label,
      value,
      minValue,
      maxValue,
      minLabel,
      maxLabel,
      allowFractional = false,
      inputWidth = '56px',
      step = 1,
      isLoading,
      formatInputValue,
      onChange,
      testId,
      disabled = false,
      tooltipText = '',
      tooltipProps,
      maxLength = 12,
      maximumDecimalPlaces = null,
      adornmentSign,
      adornmentPlacement,
    }: SliderWithTextInputProps,
    ref: ForwardedRef<SliderWithTextInputRef>,
  ) => {
    const classes = useStyles({ inputWidth, adornmentPlacement });
    const [sliderValue, setSliderValue] = useState<number>(value);
    const [inputValue, setInputValue] = useState<string>(formatInputValue(value));
    const isAnyBorderLabelDefined = maxValue || minLabel;

    useImperativeHandle(
      ref,
      () => ({
        setValue(value: number) {
          setSliderValue(value);
          setInputValue(formatInputValue(value as number));
        },
      }),
      [setSliderValue, setInputValue, formatInputValue],
    );

    const handleSliderChange = useCallback(
      (event: ChangeEvent<{}>, newValue: number | number[]) => {
        setSliderValue(newValue as number);
        setInputValue(formatInputValue(newValue as number));
      },
      [formatInputValue],
    );

    const handleChange = useCallback(
      (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (typeof newValue === 'number' && newValue >= minValue && newValue <= maxValue) {
          const regex = CreateRegex(maximumDecimalPlaces);
          if (regex.test(newValue.toString())) {
            onChange(newValue);
          }
        }
      },
      [maxValue, minValue, onChange, maximumDecimalPlaces],
    );

    const debouncedHandleChange = useMemo(() => debounce(handleChange, 350), [handleChange]);

    useEffect(() => {
      return () => {
        debouncedHandleChange.clear();
      };
    }, [debouncedHandleChange]);

    const handleTextFieldChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;

        const regex = CreateRegex(maximumDecimalPlaces);

        const numberStr = getValidNumberString(value, allowFractional);
        let numberValue = +numberStr;
        if (numberValue > maxValue) {
          numberValue = maxValue;
        }

        if (regex.test(numberValue.toString())) {
          setSliderValue(numberValue < minValue ? minValue : numberValue);
          setInputValue(shouldBeShownAsIs(numberStr, allowFractional) ? numberStr : numberValue.toString());
        }

        debouncedHandleChange(event, numberValue);
      },
      [allowFractional, debouncedHandleChange, maxValue, minValue, maximumDecimalPlaces],
    );

    const handleTextFieldFocus = useCallback(() => {
      setInputValue(getValidNumberString(inputValue, allowFractional));
    }, [inputValue, allowFractional]);

    const handleTextFieldBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setInputValue(formatInputValue(sliderValue));
        if (Number(inputValue) !== sliderValue) {
          handleChange(event, sliderValue);
        }
      },
      [formatInputValue, sliderValue, inputValue, handleChange],
    );

    const positionKey = adornmentPlacement === EAdornmentPlacement.START ? 'start' : 'end';
    const adornmentProps = adornmentSign && {
      [`${positionKey}Adornment`]: (
        <InputAdornment className={cn(classes.adornment)} position={positionKey}>
          {adornmentSign}
        </InputAdornment>
      ),
    };

    return (
      <div data-testid={testId}>
        <InputLabel disableAnimation className={classes.label}>
          {label}
        </InputLabel>
        <Tooltip {...tooltipProps} title={tooltipText} open={tooltipText ? undefined : false}>
          <div className={cn(classes.wrapper, disabled ? classes.disabled : '')}>
            <div className={classes.sliderWrapper}>
              <Slider
                min={minValue}
                max={maxValue}
                step={step}
                value={sliderValue}
                onChange={handleSliderChange}
                onChangeCommitted={handleChange}
                disabled={disabled}
              />
              {isAnyBorderLabelDefined && (
                <div className={classes.minMaxContainer}>
                  <Typography className={classes.boundaryValue} variant="body2" component="div">
                    {minLabel}
                  </Typography>
                  <Typography className={cn(classes.maxValue, classes.boundaryValue)} variant="body2" component="div">
                    {maxLabel}
                  </Typography>
                </div>
              )}
            </div>
            <TextField
              inputProps={{ maxLength }}
              className={classes.textInput}
              onChange={handleTextFieldChange}
              onFocus={handleTextFieldFocus}
              onBlur={handleTextFieldBlur}
              hiddenLabel
              InputProps={{ ...adornmentProps }}
              value={inputValue}
              disabled={disabled}
            />
            {isLoading && <Overlay size={20} testId={testId} />}
          </div>
        </Tooltip>
      </div>
    );
  },
);
