import { api } from 'constants/API';

import axios, { CancelTokenSource } from 'axios';

import { EDatabaseName } from 'helpers/constants';

import { UserInfo } from '../types/UserInfo';

export const fetchUserInfo = () => axios.get<UserInfo>(api.getUserInfo);

type TUpdateCookie = {
  shouldRefreshToken: boolean;
  domain: string;
};

export const updateAccessCookies = ({ shouldRefreshToken, domain }: TUpdateCookie, cancelToken: CancelTokenSource) =>
  axios.post<{ isAlive: boolean }>(
    `${api.updateCookie}`,
    { shouldRefreshToken, domain },
    { cancelToken: cancelToken.token },
  );

export const changeDefaultDatabaseInHeavy = (selectedDatabase: EDatabaseName, cancelToken: CancelTokenSource) =>
  axios.post(api.changeDefaultDatabaseInHeavy, { selectedDatabase }, { cancelToken: cancelToken.token });

export const updateUserStatus = () => axios.patch(api.updateUserVisitedDates);
