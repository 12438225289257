import { LineProps, XAxisProps, YAxisProps } from 'recharts';

import { formatByLocale } from 'helpers/formatters';
import { colors } from 'styles/theme/colors';
import { P_Curve } from 'types/Curve';

export const GAS_STREAMS_VALUES_DIVIDER = 6;

export const MONTH_NUMBER_DATA_KEY = 'monthNumber';
export const REAL_MONTH_DATA_KEY = 'date';

export const CHART_P_CURVES = [P_Curve.P10, P_Curve.P30, P_Curve.P70, P_Curve.P90];

export const getLineChartDefaultProps = (countSymbolsInValue: number) => ({
  margin: {
    top: 0,
    right: 0,
    left: countSymbolsInValue * 5 - 35, // hand-picked values
    bottom: 15,
  },
});

export const CartesianGridDefaultProps = {
  strokeDasharray: '3 3',
  stroke: colors.grey40,
  opacity: 0.6,
};

export const TooltipDefaultProps = {
  isAnimationActive: false,
  cursor: { stroke: colors.baseGrey30, strokeWidth: 1 },
  wrapperStyle: { zIndex: 1 },
};

export const LineDefaultProps: LineProps = {
  type: 'linear',
  dot: false,
  isAnimationActive: false,
  strokeWidth: 1,
};

const axisTickFormatter = (tickValue: number) => formatByLocale(tickValue);

export const getAxisDefaultProps = (isDarkMode: boolean): XAxisProps & YAxisProps => ({
  axisLine: {
    stroke: colors.grey40,
  },
  tick: {
    fontSize: 8,
    fontWeight: 500,
    fill: isDarkMode ? colors.baseGrey20 : colors.grey45,
  },
  tickLine: {
    stroke: isDarkMode ? colors.grey40 : colors.grey15,
  },
  tickMargin: 6,
  tickSize: 5,
  tickFormatter: axisTickFormatter,
});
