import {
  economicVariablesInitialState,
  wellDesignCapexInitialState,
  wellIdentificationInitialState,
} from 'constants/initialStates';

import { getMapSettingsSavingData } from 'app/components/common/DashboardHeaderActions/ScenarioSaveButton/utils';
import { initialState as impactCurvesSettingsInitialState } from 'app/components/containers/layout/ConfigTabs/PlannerControl/ImpactCurvesSettings/slice';
import { getInitialState as getProductionCurvesSettingsInitialState } from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesSettings/config';
import {
  ScenarioSavingData,
  ScenarioSettingsToLoadOnMap,
} from 'app/components/containers/layout/ConfigTabs/Scenario/types';
import { initialState as mapLayersInitialState } from 'app/components/containers/map/MapLayersSelect/slice';
import { initialState as mapTooltipInitialState } from 'app/components/containers/map/MapTooltipItemsSelect/slice';
import { MapWidgetId } from 'types/Widget';

export const getDefaultScenarioSettingsToLoadOnMap = (): ScenarioSettingsToLoadOnMap => ({
  activeWellId: null,
  items: [],
});

export const getUnloadableScenarioSettingsToLoadOnMap = (): ScenarioSettingsToLoadOnMap => ({
  activeWellId: null,
  items: null,
});

export const getScenarioSavedDataDefaultState = (): ScenarioSavingData => ({
  wells: [],
  mapSettings: getMapSettingsSavingData(
    mapLayersInitialState[MapWidgetId.PLAN_MAP_WIDGET].layers,
    mapTooltipInitialState[MapWidgetId.PLAN_MAP_WIDGET],
  ),
  prodCurvesSettings: getProductionCurvesSettingsInitialState(),
  impactCurvesSettings: { ...impactCurvesSettingsInitialState },
  economicsParameters: {
    wellDesignCapex: wellDesignCapexInitialState,
    wellIdentification: wellIdentificationInitialState,
    economicVariables: economicVariablesInitialState,
  },
});
