import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { IOktaVerifyState } from './types';

export const initialState: IOktaVerifyState = {
  status: RequestStatus.IDLE,
  error: RequestStatus.IDLE,
  userEmail: null,
  userId: null,
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'okta',
  initialState,
  reducers: {
    validateOkta(
      state,
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        payload: { redirectUri, code, codeVerifier },
      }: PayloadAction<{ redirectUri: string; code: string; codeVerifier: string }>,
    ) {
      state.status = RequestStatus.LOADING;
      state.error = null;
    },
    oktaValidationError(state, action: PayloadAction<string>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    clearUserInfoFromOkta(state) {
      state.status = RequestStatus.IDLE;
      state.error = null;
      state.userEmail = null;
      state.userId = null;
    },
    userInfoFromOktaLoaded(state, action: PayloadAction<{ email: string; uid: string }>) {
      state.status = RequestStatus.SUCCESS;
      state.error = null;
      state.userEmail = action.payload.email;
      state.userId = action.payload.uid;
    },
  },
});
