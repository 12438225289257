import { TestLocators } from 'constants/Locators';

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EconomicVariableSliderWithTextInput } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/common/EconomicVariableSliderWithTextInput';
import { actions as wellDesignCapexActions } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { SpEstimateData } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/SpEstimate/SpEstimateData';
import { translations } from 'locales/i18n';

import { selectManualCapexOptions, selectManualCapexValue } from '../selectors';
import { useStyles } from './styles';

export function Manual() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector(selectManualCapexValue);
  const options = useSelector(selectManualCapexOptions);
  const label = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.CAPEX.TITLE;

  const changeSliderValueHandler = useCallback(
    (value: number) => {
      dispatch(wellDesignCapexActions.changeManualCapex({ manualCapex: value }));
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      <EconomicVariableSliderWithTextInput
        key={TestLocators.WellDesignCapex_Manual_ManualCapex}
        label={label}
        testId={TestLocators.WellDesignCapex_Manual_ManualCapex}
        value={value}
        options={options}
        onChange={changeSliderValueHandler}
      />
      <SpEstimateData />
    </div>
  );
}
