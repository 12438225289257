import { useEffect, useRef } from 'react';

export const useNotInitialEffect = <T>(callback: (watchesValues?: T) => void, dependencies: any[], watches?: T) => {
  const firstUpdate = useRef(true);
  const watchesValues = useRef(watches);

  useEffect(() => {
    watchesValues.current = watches;
  });

  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;

        return;
      }
      callback(watchesValues.current);
    },
    // eslint-disable-next-line
    dependencies,
  );
};
