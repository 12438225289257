import { EDatabaseName } from 'helpers/constants';

export const createImmerseBaseUrl = (databaseType: EDatabaseName | string = EDatabaseName.Legacy_Database) => {
  return `/immerse?path=${databaseType}`;
};

export const AppRoute = {
  ROOT: '/',
  IMMERSE: '/immerse',
  PRODUCTION_PREDICTION: '/predict',
  WELL_PLANNER: '/plan',
  ECONOMICS: '/economics',
  UNAUTHORIZED: '/unauthorized',
  ERROR: '/error',
  FORBIDDEN: '/forbidden',
  DASHBOARDS: `%2Fdashboards`,
  DATA_MANAGER: `%2Fdata-manager`,
  SQL_EDITOR: `%2Fsql-editor`,
  HEAVY_IQ: `%2Fsql-notebook`,
  CREATE_DASHBOARD: `%2Fdashboard`,
  DOCUMENTATION: '/documentation',
  OKTA_LOGIN: '/oktaLogin',
  OKTA_LOGIN_PAGE: '/oktaLoginPage',
  DATABASE_ACCESS_PAGE: '/s3-databases-list',
};

export const ForceRedirectionUrl = AppRoute.ROOT;
