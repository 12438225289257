import { Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((_theme: Theme) => {
  return {
    control: {
      marginLeft: -2,
      '&:not(:last-child)': {
        marginBottom: 14,
      },
    },
    label: {
      marginTop: '-12px',
      fontSize: '8px',
      lineHeight: '39px',
    },
    curveLabel: {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: 4,
      },
    },
  };
});
