import { TestLocators } from 'constants/Locators';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line no-restricted-imports
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { socket } from 'app/containers/SocketHandler';
import { selectPriceFeed } from 'app/containers/SocketHandler/selectors';
import { actions } from 'app/containers/SocketHandler/slice';
import { PriceFeedData } from 'app/containers/SocketHandler/types';
import { formatByLocale } from 'helpers/formatters';
import { translations } from 'locales/i18n';

import { HeaderItem } from '../HeaderItem';
import { Price } from './Price';
import { PriceFeedMenu } from './PriceFeedMenu';
import { useStyles } from './styles';

export interface PriceFeedProps {
  width: Breakpoint;
}

const numberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const PriceFeedComponent = ({ width }: PriceFeedProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const priceFeed = useSelector(selectPriceFeed);
  const isWidthDownMd = isWidthDown('md', width);
  const [priceFeedAnchorEl, setPriceFeedAnchorEl] = useState<HTMLDivElement | null>(null);
  const WTIPrice = useMemo(
    () => (
      <Price
        labelKey={translations.PRICE.WTI}
        value={priceFeed.WTI}
        formatOptions={numberFormatOptions}
        tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
      />
    ),
    [priceFeed.WTI, t],
  );
  const HenryHubPrice = useMemo(
    () => (
      <Price
        labelKey={translations.PRICE.HENRY_HUB}
        value={priceFeed.HenryHub}
        formatOptions={numberFormatOptions}
        tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
      />
    ),
    [priceFeed.HenryHub, t],
  );
  const menuPrices = [WTIPrice, HenryHubPrice];

  const handlePriceFeedClick = useCallback(({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPriceFeedAnchorEl(currentTarget);
  }, []);

  const handlePriceFeedClose = useCallback(() => {
    setPriceFeedAnchorEl(null);
  }, []);

  const handleUpdatePriceFeed = useCallback(
    (data: PriceFeedData) => {
      dispatch(actions.setPriceFeed(data));
    },
    [dispatch],
  );

  useEffect(() => {
    socket?.emit('live_price:init', null, handleUpdatePriceFeed);
    socket?.on('live_price:fetch', handleUpdatePriceFeed);

    return () => {
      socket?.off('live_price:fetch', handleUpdatePriceFeed);
    };
  }, [handleUpdatePriceFeed]);

  return (
    <>
      <HeaderItem
        onClick={isWidthDownMd ? handlePriceFeedClick : undefined}
        readOnly
        isPriceFeed
        tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
        showDropDownIcon={isWidthDownMd}
        label={t(translations.PRICE.WTI, {
          value: formatByLocale(priceFeed.WTI, numberFormatOptions),
        })}
        labelClassName={isWidthDownMd ? classes.cursorPointer : ''}
        testId={TestLocators.Header_WTI}
      />
      {isWidthDownMd && (
        <PriceFeedMenu onClose={handlePriceFeedClose} anchorEl={priceFeedAnchorEl} prices={menuPrices} />
      )}
      {!isWidthDownMd && (
        <HeaderItem
          readOnly
          isPriceFeed
          tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
          label={t(translations.PRICE.HENRY_HUB, {
            value: formatByLocale(priceFeed.HenryHub, numberFormatOptions),
          })}
          testId={TestLocators.Header_HH}
        />
      )}
    </>
  );
};

export const PriceFeed = withWidth()(PriceFeedComponent);
