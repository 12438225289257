import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(2),
      backgroundColor: isDarkTheme ? colors.baseGrey57 : colors.primaryWhite,
      boxShadow: isDarkTheme ? '0 0 0 1px #444, 0 0 0 2px #121212' : 'none',
    },
    nav: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      marginRight: '10px',
    },
    divider: {
      height: 28,
      [theme.breakpoints.up('md')]: {
        marginRight: 12,
        marginLeft: 12,
      },
    },
    headerItem: {
      marginLeft: 7,
    },
    sqlEditor: {
      marginLeft: 3,
    },
    help: {
      marginLeft: 10,
    },
    heavyIQ: {
      marginLeft: 10,
    },
  });
});
