import { EDomain, host } from 'helpers/domain';

let BASE_URL = '';

if (host.includes(EDomain.IHS)) BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
else if (host.includes(EDomain.SP)) BASE_URL = process.env.REACT_APP_API_SP_URL as string;

const API_BASE_URL = `${BASE_URL}/api`;

export const BASE_URL_DATA = {
  base_url: BASE_URL,
  base_api_url: API_BASE_URL,
  socket_url: BASE_URL,
};

export const api = {
  dashboards: `${API_BASE_URL}/dashboards`,
  getUserInfo: `${API_BASE_URL}/auth/getUserInfo`,
  changeDefaultDatabaseInHeavy: `${API_BASE_URL}/auth/changeDefaultDatabase`,
  updateCookie: `${API_BASE_URL}/auth/updateCookie`,
  updateUserVisitedDates: `${API_BASE_URL}/auth/updateVisitedDates`,
  postLogInfo: `${API_BASE_URL}/log/info`,
  postLogError: `${API_BASE_URL}/log/error`,
  getShapeFilesByCountry: `${API_BASE_URL}/download/getShapeFiles`,
  getShapeFilesDownloadLink: `${API_BASE_URL}/download/getDownloadLink`,
  logout: `${API_BASE_URL}/auth/logout`,
  querySuggestions: `${API_BASE_URL}/filter/suggestions`,
  renderVega: `${API_BASE_URL}/map/renderVega`,
  getClosestResult: `${API_BASE_URL}/map/getClosestResult`,
  getMultiWellSelectionResult: `${API_BASE_URL}/map/getMultiWellSelectionResult`,
  getPredictGeoData: `${API_BASE_URL}/map/getPredictGeoData`,
  getValuesByField: `${API_BASE_URL}/table/getValuesByField`,
  getMinMaxByField: `${API_BASE_URL}/table/getMinMaxByField`,
  getWellData: `${API_BASE_URL}/table/getWellData`,
  getPredictCurvesOptions: `${API_BASE_URL}/table/getPredictCurvesOptions`,
  getCoordinateBounds: `${API_BASE_URL}/table/getCoordinateBounds`,
  getProductionCurvesData: `${API_BASE_URL}/curves/lookback`,
  getImpactCurvesData: `${API_BASE_URL}/curves/impact`,
  getImpactCurvesOptions: `${API_BASE_URL}/curves/impact/getCurvesOptions`,
  getPlanPredictCurvesData: `${API_BASE_URL}/curves/plan`,
  getWellWellParametersInitData: `${API_BASE_URL}/well-parameters/getInitOptions`,
  createBulkScenario: `${API_BASE_URL}/scenario/createBulk`,
  createScenario: `${API_BASE_URL}/scenario/create`,
  getScenariosList: `${API_BASE_URL}/scenario/get`,
  getEconomicsCalculation: `${API_BASE_URL}/scenario/economicsCalculation`,
  getEconomicsPrefillingData: `${API_BASE_URL}/scenario/economicsPrefilling`,
  updateScenario: `${API_BASE_URL}/scenario/update`,
  copyScenario: `${API_BASE_URL}/scenario/copy`,
  deleteScenario: `${API_BASE_URL}/scenario/delete`,
  shareScenario: `${API_BASE_URL}/scenario/share`,
  scenario: `${API_BASE_URL}/scenario`,
  getProductUpdatesByCategory: `${API_BASE_URL}/product-updates/get`,
  trackAllProductUpdatesAsViewed: `${API_BASE_URL}/product-updates/trackAllAsViewed`,
  getUsersToShareScenario: `${API_BASE_URL}/share/users`,
  renameScenario: `${API_BASE_URL}/scenario/rename`,
  validateWells: `${API_BASE_URL}/scenario/validateWells`,
  getDocumentationMenu: `${API_BASE_URL}/documentation/menu`,
  getDocumentationContent: `${API_BASE_URL}/documentation/content`,
  validateOktaSession: `${API_BASE_URL}/oktaVerify/validateOktaSession`,
  getAvailableDatabasesFromS3: `${API_BASE_URL}/auth/getAvailableDatabasesFromS3`,
};
