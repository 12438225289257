export const LAST_TOKEN_REFRESH_DATE = 'lastTokenRefreshDate';
const REFRESH_TIME_INTERVAL = 25;
const MILISECONDS = 60000;

export const shouldRefreshAccessToken = (lastTokenRefreshDate: string) => {
  const currentDate = new Date();
  const minutesPassed = Math.floor((currentDate.getTime() - Number(lastTokenRefreshDate)) / MILISECONDS);

  return minutesPassed >= REFRESH_TIME_INTERVAL;
};
