import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { MapLayer } from 'app/components/containers/map/MapLayersSelect/types';
import { MapWidgetId } from 'types/Widget';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { MapWidgetVegaLayerState, MapWidgetsVegaLayersState, SampleRatioParams } from './types';

const initialMapWidgetLayerState: MapWidgetVegaLayerState = {
  status: RequestStatus.IDLE,
  error: null,
  layer: null,
  table: null,
  mapLayer: null,
};

export const initialState: MapWidgetsVegaLayersState = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: initialMapWidgetLayerState,
  [MapWidgetId.ECONOMICS_MAP_WIDGET]: initialMapWidgetLayerState,
  [MapWidgetId.PLAN_MAP_WIDGET]: initialMapWidgetLayerState,
};

const mapWidgetsSlice = createSlice({
  name: 'mapWidgetsVegaLayers',
  initialState,
  reducers: {
    loadLayer(
      state,
      {
        payload,
      }: PayloadAction<{
        mapWidgetId: MapWidgetId;
        vega: string;
        sampleRatioParams?: SampleRatioParams;
        table: string;
        mapLayer?: MapLayer;
      }>,
    ) {
      state[payload.mapWidgetId].status = RequestStatus.LOADING;
      state[payload.mapWidgetId].error = null;
    },
    layerLoaded(
      state,
      { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; layer: string; table: string; mapLayer?: MapLayer }>,
    ) {
      state[payload.mapWidgetId].status = RequestStatus.SUCCESS;
      state[payload.mapWidgetId].layer = payload.layer;
      state[payload.mapWidgetId].table = payload.table;
      state[payload.mapWidgetId].mapLayer = payload.mapLayer;
    },
    layerError(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; error: string }>) {
      state[payload.mapWidgetId].status = RequestStatus.FAILURE;
      state[payload.mapWidgetId].error = payload.error;
      state[payload.mapWidgetId].layer = null;
    },
    clearLayerError(state, { payload: mapWidgetId }: PayloadAction<MapWidgetId>) {
      state[mapWidgetId].error = null;
      state[mapWidgetId].status = RequestStatus.IDLE;
    },
    layerRemove(state, { payload: mapWidgetId }: PayloadAction<MapWidgetId>) {
      state[mapWidgetId].status = RequestStatus.IDLE;
      state[mapWidgetId].error = null;
      state[mapWidgetId].layer = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsSlice;
