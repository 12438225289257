import { RequestStatus } from 'constants/RequestStatus';

import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { MapWidgetId } from 'types/Widget';

export enum MapStyle {
  DARK = 'dark-v10',
  LIGHT = 'light-v10',
  BASIC = 'basic-v9',
  SATELLITE = 'satellite-v9',
  STREETS = 'streets-v11',
  OUTDOORS = 'outdoors-v11',
  BRIGHT = 'bright-v9',
  ODYSSEY = 'cj94ar55h54o92smwiolc8shp',
  VINTAGE = 'cj7at0rnp9ei22rtegvjf58dx',
  DECIMAL = 'cj5l80zrp29942rmtg0zctjto',
  NORTH_STAR = 'cj44mfrt20f082snokim4ungi',
  CURRENT = 'current',
}

export type ClosestResultItemValueType = string | number | null;
export type ClosestResultType = Partial<Record<ImpactWellAndProdColumn, ClosestResultItemValueType> | null>;

export type MapLngLat = [number, number];
export type MapLngLatBounds = [MapLngLat, MapLngLat];

export type MapWidgetsState = Record<MapWidgetId, MapWidgetState>;
export interface MapWidgetState {
  style: MapStyle;
  center: MapLngLat | null;
  zoom: number | null;
  renderLayersEnabled: boolean;
  flyToCenter: MapLngLat | null;
  bounds: MapLngLatBounds | null;
  boundsStatus: RequestStatus;
  boundsError: string | null;
  selectedUwi: string | null;
  isFirstTimeSelectedWellSetting: boolean;
}

export interface MapWidgetClosestResultState {
  result: ClosestResultType;
  status: RequestStatus;
  error: string | null;
}

export type TPolygonData = MapLngLat[];
export interface IMapWidgetMultiWellSelectionState {
  polygonSelectedWells: string[] | null;
  geoPolygons: TPolygonData[] | null;
  status: RequestStatus;
  error: string | null;
}

export type ClosestResultTooltipDataType = Partial<Record<ImpactWellAndProdColumn, ClosestResultItemValueType>>;
