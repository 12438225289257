import { TestLocators } from 'constants/Locators';

import { useParamSelector } from 'hooks/useParamSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EconomicNumberSliderWithInputType } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/types';
import { actions as wellDesignCapexVariablesActions } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { translations } from 'locales/i18n';

import { selectWellDesignCapexCalculateOptions, selectWellDesignCapexCalculateVariable } from '../selectors';
import { NumberWellDesignCapexCalculateParameter, NumberWellDesignCapexControl } from '../types';

const labelName = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX;

type useGetEconomicVariablesDataType = {
  paramName: NumberWellDesignCapexCalculateParameter;
};

export const useGetEconomicVariablesParameters = ({ paramName }: useGetEconomicVariablesDataType) => {
  const dispatch = useDispatch();
  const value = useParamSelector(selectWellDesignCapexCalculateVariable, paramName) ?? 0;
  const options = useParamSelector(selectWellDesignCapexCalculateOptions, paramName);
  const onChangeHandler = useCallback(
    (value: number) =>
      dispatch(wellDesignCapexVariablesActions.setWellDesignCapexCalculateNumberValue({ value, paramName })),
    [dispatch, paramName],
  );

  return { value, options, onChangeHandler };
};

export const useGetWellDesignCapexData = (): EconomicNumberSliderWithInputType[] => {
  return [
    {
      label: labelName.TRUE_VERTICAL_DEPTH.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_TrueVerticalDepth,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.TRUE_VERTICAL_DEPTH }),
    },
    {
      label: labelName.LATERAL_LENGTH.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_LateralLength,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.LATERAL_LENGTH }),
    },
    {
      label: labelName.PROPPANT_VOLUME.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_ProppantVolume,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.PROPPANT_VOLUME }),
    },
  ];
};
