import { SpEstimateData } from './SpEstimateData';
import { useStyles } from './styles';

export function SpEstimate() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SpEstimateData />
    </div>
  );
}
