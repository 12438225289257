import {
  AVAILABLE_COMODITY_PRICES_GAS,
  AVAILABLE_COMODITY_PRICES_OIL,
} from 'app/components/containers/layout/ConfigTabs/EconomicsControl/EconomicVariables/constants';
import { EconomicVariablesState } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/EconomicVariables/types';
import { AVAILABLE_WELL_ORIENTATION_OPTIONS } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { WellDesignCapexState } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/types';
import {
  EToggleSelection,
  WellIdentificationState,
} from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellIdentification/types';

import { RequestStatus } from './RequestStatus';

export const economicVariablesInitialState: EconomicVariablesState = {
  current: {
    commodityPricesOil: AVAILABLE_COMODITY_PRICES_OIL[0],
    commodityPricesGas: AVAILABLE_COMODITY_PRICES_GAS[0],
    fixedOpex: 20000,
    variableOpex: 38,
    inflationEscalationRate: 2,
    royalty: 50,
    cashTax: 15,
    sevAdValTaxByRevenueOil: 15,
    sevAdValTaxByRevenueGas: 15,
    taxPerBblOil: 10,
    taxPerMcfGas: 0,
    simulateCashflowCutoff: true,
  },
  options: {
    commodityPricesOil: AVAILABLE_COMODITY_PRICES_OIL,
    commodityPricesGas: AVAILABLE_COMODITY_PRICES_GAS,
    fixedOpex: { min: 0, max: 40000 },
    variableOpex: { min: 0, max: 75 },
    inflationEscalationRate: { min: 0, max: 20 },
    royalty: { min: 0, max: 99 },
    cashTax: { min: 0, max: 50 },
    sevAdValTaxByRevenueOil: { min: 0, max: 20 },
    sevAdValTaxByRevenueGas: { min: 0, max: 20 },
    taxPerBblOil: { min: 0, max: 20 },
    taxPerMcfGas: { min: 0, max: 2 },
  },
};

export const economicVariablesDataForSaving: Partial<EconomicVariablesState> = { ...economicVariablesInitialState };
delete economicVariablesDataForSaving.options;

export const wellIdentificationInitialState: WellIdentificationState = {
  toggleselection: EToggleSelection.SINGLE,
  uwi: '',
  wellIdentityStatus: RequestStatus.IDLE,
  wellIdentityError: '',
  wellIdentity: {
    wellName: '',
    wellNumber: '',
    basin: '',
    playDesignation: '',
    reservoir: '',
    statusCurrentProd: '',
    dateVintage: '',
    country: '',
    Operator: '',
  },
};

export const wellIdentificationDataForSaving: Partial<WellIdentificationState> = { ...wellIdentificationInitialState };
delete wellIdentificationDataForSaving.wellIdentityError;
delete wellIdentificationDataForSaving.wellIdentityStatus;

export const enum EWellDesignCapexOption {
  SP_ESTIMATE = 'spEstimate',
  CALCULATE = 'calculate',
  MANUAL = 'manual',
}

export const wellDesignCapexInitialState: WellDesignCapexState = {
  calculate: {
    current: {
      wellOrientation: 'Horizontal',
      trueVerticalDepth: 10000,
      lateralLength: 10000,
      proppantVolume: 3200,
      wellVintageDate: '2021-12-10T23:59:59',
      wellVintageDateError: null,
    },
    options: {
      wellOrientation: AVAILABLE_WELL_ORIENTATION_OPTIONS,
      trueVerticalDepth: { min: 0, max: 20000 },
      lateralLength: { min: 0, max: 20000 },
      proppantVolume: { min: 0, max: 6400 },
    },
  },
  manual: {
    current: {
      manualCapex: 5000000,
    },
    options: {
      manualCapex: { min: 0, max: 25000000 },
    },
  },
  wellDesignCapexErrors: [],
  spEstimate: '9200000000',
  activeTab: EWellDesignCapexOption.CALCULATE,
};

export const wellDesignCapexDataForSaving: Partial<WellDesignCapexState> = { ...wellDesignCapexInitialState };
delete wellDesignCapexDataForSaving.activeTab;

export const economicsParametersInitialState = {
  economicVariables: economicVariablesInitialState,
  wellDesignCapex: wellDesignCapexInitialState,
  wellIdentification: wellIdentificationInitialState,
};
