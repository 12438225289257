import { TestLocators } from 'constants/Locators';

import { InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectSpEstimate } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/selectors';
import { formatByLocale } from 'helpers/formatters';
import { translations } from 'locales/i18n';

import { useStyles } from './styles';

export function SpEstimateData() {
  const { t } = useTranslation();
  const classes = useStyles();
  const spEstimate = formatByLocale(Math.round(Number(useSelector(selectSpEstimate))));
  const label = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.SP_ESTIMATE.TITLE;

  return (
    <InputLabel className={classes.label} data-testid={TestLocators.WellDesignCapex_SpEstimate_SpEstimateData}>
      {t(label)}
      {` $${spEstimate}`}
    </InputLabel>
  );
}
