import { RequestStatus } from 'constants/RequestStatus';

import {
  CalculateType,
  ManualType,
  WellDesignCapexState,
} from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/types';
import { ProductionModelType } from 'types/ProductionModel';

import { EconomicVariablesState, TCommodityPriceObject } from '../EconomicVariables/types';

export interface IWellIdentity {
  wellName: string | number | null;
  wellNumber: string | number | null;
  basin: string | number | null;
  playDesignation: string | number | null;
  reservoir: string | number | null;
  statusCurrentProd: string | number | null;
  dateVintage: string | number | null;
  country: string | number | null;
  Operator: string | number | null;
}

export interface ISavedData {
  uwi: string;
  wellDesignCapex: {
    calculate: CalculateType;
    manual: ManualType;
    spEstimate: string;
  };
  productionModel: {
    type: ProductionModelType;
  };
  economicVariables: {
    commodityPricesOil: TCommodityPriceObject;
    commodityPricesGas: TCommodityPriceObject;
    fixedOpex: number;
    variableOpex: number;
    inflationEscalationRate: number;
    royalty: number;
    cashTax: number;
    sevAdValTaxByRevenueOil: number;
    sevAdValTaxByRevenueGas: number;
    taxPerBblOil: number;
    taxPerMcfGas: number;
  };
}

export enum EToggleSelection {
  MULTI = 'multi',
  SINGLE = 'single',
}

export interface WellIdentificationState {
  toggleselection: EToggleSelection;
  uwi: string;
  wellIdentityStatus: RequestStatus;
  wellIdentityError: string | null;
  wellIdentity: IWellIdentity;
}

export type TParametersPrefellingResponseData = {
  wellIdentification: WellIdentificationState;
  wellDesignCapex: WellDesignCapexState;
  economicVariables: EconomicVariablesState;
};
