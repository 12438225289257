import { ThemeMode } from 'impact-react-components';

import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const overrideStyles = (useLabelMargin = true) =>
  withStyles((theme: Theme) => {
    const { type } = theme.palette;
    const isDarkTheme = type === ThemeMode.DARK;

    return createStyles({
      root: {
        width: '100%',
        'label + &': useLabelMargin
          ? {
              marginTop: 6,
            }
          : null,
      },
      input: {
        borderRadius: 2,
        position: 'relative',
        backgroundColor: isDarkTheme ? colors.baseGrey80 : colors.primaryWhite,
        border: isDarkTheme ? 'none' : `1px solid ${colors.grey15}`,
        fontSize: 16,
        width: '100%',
        height: 32,
        padding: '0 8px',
      },
    });
  });
