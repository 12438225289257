import { ThemeMode } from 'impact-react-components';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

import { EAdornmentPlacement } from './types';

export const useStyles = makeStyles((theme: Theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    sliderWrapper: {
      width: '100%',
      marginRight: 16,
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      transform: 'none',
    },
    textInput: {
      width: ({ inputWidth }: { inputWidth: string; adornmentPlacement?: EAdornmentPlacement }) => inputWidth,
      flexShrink: 0,
      marginLeft: 'auto',
      background: '#333333',
      color: 'currentColor',
      '& > div': {
        padding: '0',
        margin: '0',
        '& > input': {
          marginLeft: ({ adornmentPlacement }: { inputWidth: string; adornmentPlacement?: EAdornmentPlacement }) =>
            adornmentPlacement === EAdornmentPlacement.START ? '-3px' : '6px',
        },
      },
    },
    minMaxContainer: {
      display: 'flex',
      width: '100%',
      fontSize: 10,
      color: isDarkTheme ? colors.baseGrey20 : colors.grey45,
      lineHeight: '10px',
      marginTop: '2px',
    },
    boundaryValue: {
      lineHeight: '10px',
      fontSize: 10,
    },
    maxValue: {
      marginLeft: 'auto',
    },
    disabled: {
      cursor: 'not-allowed',
    },
    adornment: {
      '& > p': {
        color: 'currentColor !important',
        transform: ({ adornmentPlacement }: { inputWidth: string; adornmentPlacement?: EAdornmentPlacement }) =>
          adornmentPlacement === EAdornmentPlacement.START ? 'translateX(3px)' : 'translateX(-3px)',
      },
    },
  });
});
