import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export interface StyleProps {
  isPriceFeed: boolean;
}

export const useStyles = makeStyles((theme) => {
  const { type, primary, text } = theme.palette;

  return createStyles({
    headerItem: {
      display: 'flex',
      height: 48,
      alignItems: 'center',
      padding: '0 5px',
      margin: '0 7px',
      [theme.breakpoints.down('md')]: {
        padding: '0 3px',
        margin: '0 5px',
      },
      cursor: 'pointer',
      position: 'relative',
    },
    basicColors: {
      color: ({ isPriceFeed }: StyleProps) =>
        type === ThemeMode.DARK
          ? isPriceFeed && type === ThemeMode.DARK
            ? colors.baseGrey20
            : colors.baseGrey10
          : colors.grey40,
      '& .label': {
        color: ({ isPriceFeed }: StyleProps) => (isPriceFeed ? colors.baseGrey20 : colors.baseGrey10),
        '&:hover': {
          color: ({ isPriceFeed }: StyleProps) => (isPriceFeed ? colors.baseGrey20 : colors.baseGrey10),
        },
      },
    },
    readOnly: {
      cursor: 'default',
    },
    disabled: {
      cursor: 'default',
      '& $label': {
        color: text.secondary,
        '&:hover': {
          color: text.secondary,
        },
      },
    },
    label: {
      '&:hover': {
        color: ({ isPriceFeed }: StyleProps) => (isPriceFeed ? colors.baseGrey20 : colors.baseGrey10),
      },
    },
    arrowIcon: {
      fontSize: '16px !important',
      marginRight: -6,
      verticalAlign: 'middle',
    },
    animatedBorder: {
      background: primary.main,
      width: '100%',
      height: 3,
      position: 'absolute',
      bottom: 0,
      left: 0,
      animation: '$showHighlightBar 0.25s',
    },
    '@keyframes showHighlightBar': {
      '0%': { transform: 'scale(0, 1)' },
      '100%': { transform: 'scale(1, 1)' },
    },
  });
});
