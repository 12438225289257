import { ScenarioListResponseItem } from 'api/scenarios';

import {
  PlanWellParameterName,
  PlanWellParameters,
  WellParametersWells,
} from 'app/components/containers/layout/ConfigTabs/PlannerFilter/WellParametersSection/types';
import { WellSettings } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/types';
import { ScenariosPermission } from 'types/Scenario';
import { NonNullableKeys } from 'utils/types/non-nullable-keys';

import { getDefaultScenarioSettingsToLoadOnMap, getScenarioSavedDataDefaultState } from './config';
import { DEFAULT_SCENARIO_NAME } from './constants';
import { Scenario, ScenarioSettingsToLoadOnMap, ScenariosState, WellSavingData } from './types';

export function getScenariosList(responseItems: ScenarioListResponseItem[]): Scenario[] {
  return responseItems.map((item) => ({
    id: item.detailsId,
    name: item.scenarioName,
    permission: item.permission,
    isShared: item.isShared,
    ownerUserId: item.ownerUserId,
  }));
}

export function buildMapWellsSettings(wells: WellSavingData[]): ScenarioSettingsToLoadOnMap {
  const items: WellSettings[] = wells.map((item) => {
    return {
      id: item.id,
      name: item.name,
      color: item.color,
      hidden: false,
      startPoint: item.startPoint,
      endPoint: item.endPoint,
      activePointType: null,
      tempLengthLimitPoint: null,
    };
  });
  const activeId = wells.find((item) => item.active);

  return {
    activeWellId: activeId ? activeId.id : null,
    items,
  };
}

export function buildWellParametersWellsSettings(wells: WellSavingData[]): WellParametersWells {
  const wellsSettings: WellParametersWells = {};

  wells.forEach((item) => {
    const value: NonNullableKeys<PlanWellParameters> = {
      [PlanWellParameterName.PLAY_DESIGNATION]: item[PlanWellParameterName.PLAY_DESIGNATION],
      [PlanWellParameterName.RESERVOIR]: item[PlanWellParameterName.RESERVOIR],
      [PlanWellParameterName.LATERAL_LENGTH]: item[PlanWellParameterName.LATERAL_LENGTH],
      [PlanWellParameterName.PROPPANT_PER_FT]: item[PlanWellParameterName.PROPPANT_PER_FT],
      [PlanWellParameterName.PROPPANT_PER_FLUID_RATIO]: item[PlanWellParameterName.PROPPANT_PER_FLUID_RATIO],
      [PlanWellParameterName.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING]:
        item[PlanWellParameterName.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING],
    };

    wellsSettings[item.id] = value;
  });

  return wellsSettings;
}

export function getBasinFromWellsData(wells: WellSavingData[]) {
  const wellItem = wells.find((item) => item.geologic_province);

  return wellItem ? wellItem.geologic_province : null;
}

export function resetScenarioStateToInitPoint(state: ScenariosState) {
  state.name = DEFAULT_SCENARIO_NAME;
  state.scenarioDetailsId = null;
  state.scenarioPermission = ScenariosPermission.OWNER;
  state.scenarioSettingsToLoadOnMap = getDefaultScenarioSettingsToLoadOnMap();
  state.dataForSaving = getScenarioSavedDataDefaultState();
  state.lastSavedData = getScenarioSavedDataDefaultState();
  state.previousVersionDatetime = null;
}
