import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { EMapWidget } from 'app/components/common/MapWidgetContainer/constants';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { RootState } from 'types/RootState';

import { GROPED_TAB_COLUMNS } from './constants';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.wellInfoWidget || initialState;

export const selectWellInfoData = createSelector(
  [selectDomain, (state, type: EMapWidget) => type],
  (data, type) => data[type].data,
);
export const selectIsWellInfoLoading = createSelector([selectDomain], ({ status }) => status === RequestStatus.LOADING);
export const selectWellInfoError = createSelector([selectDomain], ({ error }) => error);
export const selectWellStatus = createSelector([selectDomain, (state, type: EMapWidget) => type], (state, type) =>
  state[type].data !== null ? state[type].data?.Status_Well : null,
);

export const selectValueGroups = createSelector([selectDomain, (state, type: EMapWidget) => type], (state, type) =>
  GROPED_TAB_COLUMNS.map((tabColumns) =>
    tabColumns.map((column) => {
      switch (column) {
        case ImpactWellAndProdColumn.LATITUDE_TOE: {
          return {
            id: column,
            value: state[type].data?.[ImpactWellAndProdColumn.LATITUDE_BH] ?? null,
          };
        }
        case ImpactWellAndProdColumn.LONGITUDE_TOE: {
          return {
            id: column,
            value: state[type].data?.[ImpactWellAndProdColumn.LONGITUDE_BH] ?? null,
          };
        }
        default: {
          return {
            id: column,
            value: state[type].data?.[column] ?? null,
          };
        }
      }
    }),
  ),
);
