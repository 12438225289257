import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type, primary } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    profile: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      cursor: 'pointer',
      paddingLeft: 5,
      marginLeft: ({ isJupyterEnabled }: { isJupyterEnabled: boolean }) => (isJupyterEnabled ? 7 : 25),
      marginRight: ({ isJupyterEnabled }: { isJupyterEnabled: boolean }) => (isJupyterEnabled ? -5 : 10),
      color: isDarkTheme ? colors.baseGrey10 : colors.grey42,
      ...(isDarkTheme ? {} : { '&:hover': { color: colors.primary } }),
    },
    profileBackground: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'initial',
      margin: 0,
      justifyContent: 'center',
      height: '36px',
      width: '36px',
      borderRadius: '50%',
      backgroundColor: isDarkTheme ? colors.baseGrey57 : colors.baseGrey10,
    },
    profileIcon: {
      width: 32,
      height: 32,
      padding: 3,
    },
    arrowIcon: {
      fontSize: 16,
      marginLeft: -2,
      marginRight: -3,
      verticalAlign: 'middle',
    },
    animatedBorder: {
      background: primary.main,
      width: '100%',
      height: 3,
      position: 'absolute',
      bottom: 0,
      left: 0,
      animation: '$showHighlightBar 0.25s',
    },
    '@keyframes showHighlightBar': {
      '0%': { transform: 'scale(0, 1)' },
      '100%': { transform: 'scale(1, 1)' },
    },
  });
});
